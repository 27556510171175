import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Company } from '@/modules/company/types';
import { MenuShareProfileButton } from '../../share-profile';
import { Description } from './description.component';
import { PageAddress } from './page-address.component';
import { CompanyCredentialsForm } from '../types';
import s from './company-credentials.module.scss';
import { Media } from './media.component';
import { Banners } from './banners.component';
import { ConfirmBeforeUnloadModal } from '../modals';
import { getDirty } from '../helpers';

type CompanyCredentialsProps = {
  data: Company;
  name: string | undefined;
  avatar: File | undefined;
  onsubmit: SubmitHandler<CompanyCredentialsForm>;
  isPending: boolean;
  isError: boolean;
};

export const CompanyCredentials = ({
  data, name, avatar, onsubmit, isPending, isError,
}: CompanyCredentialsProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    setFocus,
    watch,
  } = useFormContext<CompanyCredentialsForm>();
  const { isDesktop } = useMatchMedia();
  const [pendingRoute, setPendingRoute] = useState<string>();
  const [routeChangeBlocked, setRouteChangeBlocked] = useState(false);
  const dirty = getDirty({
    watch, data, name, avatar,
  });

  useEffect(() => {
    const firstError = Object
      .keys(errors).reduce((field, a) => (field && !!errors[field] ? field : a), null);
    if (firstError) {
      setFocus(firstError as keyof CompanyCredentialsForm);
    }
  }, [errors, isError]);

  let routeAborted = false;

  const handleBeforeUnload = (e: string) => {
    if (routeAborted) return;

    setIsConfirmModalOpen(true);
    setPendingRoute(e);
    router.events.emit('routeChangeError');
    routeAborted = true;
    throw 'RouteChange aborted by the user';
  };

  useEffect(() => {
    setRouteChangeBlocked(!!dirty);
  }, [dirty]);

  useEffect(() => {
    if (!routeChangeBlocked) return;

    router.events.on('routeChangeStart', handleBeforeUnload);

    return () => {
      router.events.off('routeChangeStart', handleBeforeUnload);
    };
  }, [routeChangeBlocked]);

  const handleConfirm = useCallback(() => {
    setIsConfirmModalOpen(false);
    setPendingRoute(undefined);
    handleSubmit(onsubmit)();
  }, [handleSubmit, onsubmit]);

  const handleReject = useCallback(() => {
    setRouteChangeBlocked(false);
    routeAborted = false;
    setIsConfirmModalOpen(false);
    router.events.off('routeChangeStart', handleBeforeUnload);
    if (pendingRoute) {
      router.push(pendingRoute);
    }
  }, [router, pendingRoute, handleBeforeUnload]);

  return (
    <>
      <Description description={data.description} />
      <PageAddress path={data.custom_page_url} id={data.id} />
      <Media media={data.social_networks} />
      <Banners companyBanners={data.banners} />
      {isDesktop && (
        <div className={s.submit_wrapper}>
          <Button
            type="submit"
            appearance="primary"
            buttonColor="blue"
            buttonSize="m"
            disabled={isPending || !!Object.keys(errors).length || !dirty}
            className={s.submit}
          >
            {isPending ? t('common:submitting') : t('userAccount:company.save_changes')}
          </Button>
          <MenuShareProfileButton
            text={t('userAccount:company.view_card')}
            className={s.view}
          />
        </div>
      )}
      <ConfirmBeforeUnloadModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleConfirm}
        onReject={handleReject}
      />
    </>
  );
};
