import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { AxiosError } from 'axios';
import { useNetworkState } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import {
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
} from 'react-hook-form';
import { triggerEvent } from '@/modules/rollbar';
import { updateQueriesByKeys } from '@/modules/shared/helpers';
import { ErrorResponse } from '@/modules/shared/types';
import { useUpdateAdMutation } from '../../../mutations';
import {
  ADS_LIST_QUERY_KEY,
  INFINITE_LIST_QUERY_KEY,
  MY_ADS_LIST_QUERY_KEY,
  MY_ADS_INFINITY_LIST_QUERY_KEY,
} from '../../../consts';
import { EditAdTypes } from '../types';


interface Props {
  adId: string;
  reset: UseFormReset<EditAdTypes>;
  setError: UseFormSetError<EditAdTypes>,
  getValues: UseFormGetValues<EditAdTypes>,
  setIsSubmitSuccessful: Dispatch<SetStateAction<boolean>>;
}

export const useEditAdMutation = ({
  reset,
  setError,
  getValues,
  setIsSubmitSuccessful,
}: Props) => {
  const { t } = useTranslation();
  const { online } = useNetworkState();

  const create = useUpdateAdMutation([
    [INFINITE_LIST_QUERY_KEY],
    [ADS_LIST_QUERY_KEY],
    [MY_ADS_LIST_QUERY_KEY],
    [MY_ADS_INFINITY_LIST_QUERY_KEY],
  ]);
  const [retryData, setRetryData] = useState<Partial<EditAdTypes> | null>(null);

  const queryClient = useQueryClient();
  let adId: string | null;

  const updateAd = async ({ id, data }: { id: string, data: Partial<EditAdTypes> }) => {
    adId = id;
    try {
      create.mutate({ id, body: data }, {
        onSuccess: async () => {
          reset();
          setRetryData(null);
          setIsSubmitSuccessful(true);

          await updateQueriesByKeys(queryClient, [
            ADS_LIST_QUERY_KEY,
            INFINITE_LIST_QUERY_KEY,
            MY_ADS_LIST_QUERY_KEY,
            MY_ADS_INFINITY_LIST_QUERY_KEY,
            'me',
          ]);
        },
        onError: (error: AxiosError<ErrorResponse>) => {
          console.error('Product editing failed: ', error);

          if (error.response?.data) {
            const currentValues = getValues();
            /**
             * FIXME:
             * когда форма отправляется, isSubmitSuccessful становится true и блокирует кнопку отправки формы в
             * компоненте src/modules/ads/components/create-ad/components/buttons.component.tsx даже если форма
             * отправилась с ошибкой. Если сделать state и устанавливать его в true в случае успешной отправки
             * некорректно блокирует кнопку, есть 1-2 секунды когда кнопка активна и можно отправить объявление еще раз
             * */
            reset({ ...currentValues }, { keepErrors: true });

            Object.entries(error.response.data.validation_errors).forEach(
              ([field, errorDetails]) => {
                setError(field as keyof EditAdTypes, {
                  type: 'manual',
                  message: t(`forms:validationErrors.${errorDetails.code}`),
                });
              },
            );
          }
        },
      });
    } catch (error) {
      console.error('Product creation failed:', error);
      triggerEvent(error);

      if (!error.response) {
        setRetryData(data); // сохраняем данные для повторной отправки при восстановлении сети
        setError('root', {
          type: 'manual',
          message: t('createAd:noInternet'),
        }); // устанавливаем ошибку на форму, чтобы isSubmitSuccessful не стал true
      }
    }
  };

  useEffect(() => {
    if (retryData && online && adId) {
      updateAd({ id: adId, data: retryData })
        .then(() => setRetryData(null))
        .catch((error) => console.error('Retry failed: ', error));
    }
  }, [online, retryData]);

  return {
    updateAd,
  };
};
