import { IncomingMessage } from 'node:http';
import { TLSSocket } from 'tls';

export const getServerHost = (req: IncomingMessage) => {
  const proto = req.headers['x-forwarded-proto'] || (req.connection as TLSSocket)?.encrypted
    ? 'https'
    : 'http';

  return (req.headers.host
    ? `${proto}://${req.headers.host}`
    : '');
};
