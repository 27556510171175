import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from './social-media-item-view.module.scss';

interface SocialMediaItemViewProps {
  link: {
    platform: string;
    url: string;
    username: string;
  };
}

export const SocialMediaItemView = ({ link }: SocialMediaItemViewProps) => {
  const { isMobile, isTablet } = useMatchMedia();

  return (
    <Link
      href={link.url}
      target="_blank"
      className={clsx(styles.item, styles.item_view)}
    >
      <SvgIcon
        name={`${link.platform}`.toLowerCase()}
        style={{ fontSize: (isMobile || isTablet) ? '32px' : '24px', minWidth: '24px' }}
      />
      <span className={styles.item_text}>
        {(!isMobile && !isTablet) && link.username}
      </span>
    </Link>
  );
};
