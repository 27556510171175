export const INSTAGRAM_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:instagram\.com\/)?@?([\w.]+)/;
export const TG_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:t\.me\/)?@?([\w.]+)/;
export const YOUTUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:@|user\/|channel\/)?|youtu\.be\/)([a-zA-Zа-яА-ЯёЁ0-9\-_]+)/;
export const TWITTER_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/@?([\w]+)/;
export const WA_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:wa\.me\/)?@?([\w.]+)/;

export const DESCRIPTION_REGEXP = /[^,.!?'"\-_:;()@#$%^&*²°№’฿₽[\]a-zA-Zа-яА-ЯёЁ0-9\s\p{Emoji}]/gu;

// пока поступил запрос на проверку только youtube и twitter (/^[\s\S]*$/ - любая строка)
export const MEDIA_USERNAMES = {
  instagram: /^[\s\S]*$/,
  telegram: /^[\s\S]*$/,
  youtube: /^@?[a-zA-Zа-яА-ЯёЁ0-9\-_.·]{3,30}(?<![-_.·])$/,
  twitter: /^@?[\w_]{1,15}$/,
  whatsapp: /^[\s\S]*$/,
};

// переменные с отдельными значениями выше используются в других компонентах
export const MEDIA_LINKS = {
  instagram: /(?:https?:\/\/)?(?:www\.)?(?:instagram\.com\/)?@?([\w.]+)/,
  telegram: /(?:https?:\/\/)?(?:www\.)?(?:t\.me\/)?@?([\w.]+)/,
  youtube: /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:@|user\/|channel\/)?|youtu\.be\/)([a-zA-Zа-яА-ЯёЁ0-9\-_]+)/,
  twitter: /(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/@?([\w]+)/,
  whatsapp: /(?:https?:\/\/)?(?:www\.)?(?:wa\.me\/)?@?([\w.]+)/,
};
