import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@/modules/shared/components';
import s from './modal-edit-price.module.scss';
import { PriceForm } from './types';

type ActionButtonsProps = {
  onCancel: () => void;
  isPending: boolean;
};

export const ActionButtons = ({ onCancel, isPending }: ActionButtonsProps) => {
  const { t } = useTranslation();
  const { formState: { errors } } = useFormContext<PriceForm>();
  const price = useWatch({ name: 'price' });
  const price_type = useWatch({ name: 'price_type' });

  const isDisabled = useMemo(
    () => isPending || !!Object.keys(errors).length
      || (!price && !price_type)
      || (price_type === 'for_money' && !price?.trim()),
    [isPending, errors, price, price_type],
  );

  return (
    <div className={s.buttons}>
      <Button
        buttonSize="s"
        appearance="primary"
        buttonColor="grey"
        onClick={onCancel}
      >
        {t('common:cancel_verb')}
      </Button>
      <Button
        type="submit"
        buttonSize="s"
        appearance="primary"
        buttonColor="blue"
        disabled={isDisabled}
      >
        {isPending ? t('common:submitting') : t('common:apply')}
      </Button>
    </div>
  );
};
