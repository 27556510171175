import useTranslation from 'next-translate/useTranslation';
import { InputPrice, InputText } from '@/modules/shared/components';
import s from './modal-edit-price.module.scss';

type OldPriceProps = {
  price: number;
  price_type: string | undefined;
};

export const OldPrice = ({ price, price_type }: OldPriceProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.form__content__old_price}>
      <p className={s.header}>{t('userProfile:editPriceModal.oldPrice')}</p>
      {price_type === 'for_money' ? (
        <InputPrice
          defaultValue={price}
          disabled
        />
      ) : (
        <InputText
          defaultValue={t(`common:${price_type === 'by_agreement' ? 'byAgreement' : 'forFree'}`)}
          disabled
          fullWidth
        />
      )}
    </div>
  );
};
