import { useRouter } from 'next/navigation';
import useTranslation from 'next-translate/useTranslation';
import { MobileHeader } from '@/modules/app/components/header';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import { SuccessPage } from '@/modules/shared/components';
import { IAd } from '../../../types';
import { AdLink } from '../../ad-link';
import styles from '../edit-ad.module.scss';

export const EditAdSuccessPage = ({ ad }: { ad: IAd }) => {
  const { t, lang } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { push } = useRouter();

  const link = `/${lang}/${profileBasePath}/my-ads?tab=active`;

  return (
    <>
      {!isDesktop && (
        <MobileHeader
          showBackButton={false}
          onClose={() => push(link)}
        />
      )}
      <SuccessPage
        header={t('edit-ad:successPage.title')}
        infoText={t('edit-ad:successPage.subtitle')}
        linkToAccount={link}
        linkToAccountText={t('edit-ad:successPage.chooseAnotherAd')}
        buttonSlot={(
          <AdLink ad={ad} className={styles.go_to_ad_link}>
            {t('edit-ad:successPage.goToAd')}
          </AdLink>
        )}
      />
    </>
  );
};
