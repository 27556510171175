import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './user-avatar.module.scss';

interface UserAvatarProps {
  srcAvatar: string | null;
  userName: string;
  diameter?: '16px' | '24px' | '32px' | '36px' | '44px' | '48px' | '64px' | '66px' | '100px' | '140px' | '152px' | '255px';
  style?: React.CSSProperties;
  qrCode?: string | null;
  isAnimated?: boolean;
  firstFlip?: boolean;
  isOnline?: boolean;
}

const fontSizeMap: Record<string, string> = {
  '255px': '98px',
  '152px': '48px',
  '140px': '96px',
  '100px': '48px',
  '66px': '40px',
  '64px': '40px',
  '48px': '32px',
  '44px': '24px',
  '36px': '24px',
  '32px': '20px',
  '24px': '16px',
  '16px': '11px',
};

export const UserAvatar = ({
  srcAvatar,
  userName,
  diameter = '66px',
  style,
  isAnimated = false,
  qrCode,
  firstFlip = false,
  isOnline = false,
}: UserAvatarProps) => {
  const fontSize = fontSizeMap[diameter];
  const userNameAvatar = userName?.slice(0, 1)?.toUpperCase();

  const [isFlipping, setIsFlipping] = useState(false);

  const handleClick = () => {
    if (isAnimated) {
      setIsFlipping((prev) => !prev);
    }
  };

  const border = parseInt(diameter, 10) > 48 ? '1px solid #CDCDCF' : '0.5px solid #CDCDCF';

  return (
    <div
      className={clsx(
        styles.avatar,
        { [styles.flipping]: isFlipping },
        { [styles.first_flip]: firstFlip },
        { [styles.transparent_bg]: srcAvatar },
        { [styles.relative]: isOnline },
      )}
      style={{
        width: diameter,
        height: diameter,
        border,
        ...style,
      }}
      onClick={handleClick}
    >
      <div className={styles.front}>
        {srcAvatar ? (
          <span className={styles.avatar_img}>
            <img src={srcAvatar} alt={userNameAvatar} />
          </span>
        ) : (
          <span style={{ fontSize }}>{userNameAvatar}</span>
        )}
      </div>
      {isAnimated && qrCode && (
        <div className={styles.back}>
          <span className={styles.avatar_img}>
            <img src={qrCode} alt="QR code" className={styles.avatar_img__qr} />
          </span>
        </div>
      )}
      {isOnline && (
        <div className={clsx(
          styles.avatar__online,
          { [styles.avatar__online__small]: parseInt(diameter, 10) < 64 },
        )}
        />
      )}
    </div>
  );
};
