import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  myCompany: () => `${baseEndpointV1}/company/my/`,
  userCompanyById: (company: { id: number | string | null }) => `${baseEndpointV1}/company/${company.id}/`,
  userCompanyByUrl: (company: { url: string }) => `${baseEndpointV1}/company/detail/${company.url}/`,
  uploadLogo: () => `${baseEndpointV1}/company/logo/create/`,
  uploadBanners: () => `${baseEndpointV1}/company/banner/`,
  qrCode: (company_id: number) => `${baseEndpointV1}/company/${company_id}/qr-code/`,
};
