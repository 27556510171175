import { last } from 'lodash';
import { uploadFileToS3 } from './upload-file-to-s3';
import { generateThumbnail, compressImage } from '../helpers';
import { api, bindContext } from './api';
import { ImageFormat, EntityImage, FormImage } from '../types';
import { SIZE_TO_COMPRESS } from '../const';
import { apiWithoutToast } from './api-without-toast';

type EndpointFn<EndpointParams = unknown> = (params?: EndpointParams) => string;

export type SingleFilterValue = string | number | boolean | null;
export interface IListRequestConfig {
  url?: string;
  order?: string;
  pageSize?: number;
  filters?: Record<string, SingleFilterValue | SingleFilterValue[]>;
  paginationType?: 'page_number' | 'cursor';
  page?: number;
}

export interface ChatApiListRequestConfig extends IListRequestConfig {
  recipient: number;
  announcement?: number;
}

export const fetchList = async function <
  RawResponse = unknown,
  EndpointParams = unknown,
>(
  endpoint: EndpointFn<EndpointParams>,
  config: IListRequestConfig,
) {
  const {
    url,
    order,
    pageSize,
    filters = {},
    paginationType,
    page,
  } = config;
  const { data } = await bindContext(api.get, this)<RawResponse>(url || endpoint(), {
    params: {
      ...filters,
      ordering: order,
      page_size: pageSize,
      pagination_type: paginationType,
      page,
    },
  });

  return data;
};

export const destroy = async <EndpointParams>(
  endpoint: EndpointFn<EndpointParams>,
  options: EndpointParams,
) => {
  const { data } = await api.delete(endpoint(options));
  return data;
};

export const create = async <Body, Response, EndpointParams = unknown>(
  endpoint: EndpointFn<EndpointParams>,
  body: Body,
  options?: EndpointParams,
) => {
  const { data } = await api.post<Response>(endpoint(options), body);
  return data;
};
export const createWithoutToast = async <Body, Response, EndpointParams = unknown>(
  endpoint: EndpointFn<EndpointParams>,
  body: Body,
  options?: EndpointParams,
) => {
  const { data } = await apiWithoutToast.post<Response>(endpoint(options), body);
  return data;
};

export const saveEntityImages = async (
  images: FormImage[],
  createImageApi: (formats: ImageFormat[]) => Promise<EntityImage[]>,
) => {
  const savedImages = images.filter(
    (image): image is EntityImage => !(image instanceof File),
  );

  const newImages = images.filter(
    (image): image is File => image instanceof File,
  );
  const formats = newImages.map((image) => image.type.split('/')[1] as ImageFormat);
  const createdImages = await createImageApi(formats);

  const uploadPromises = newImages.map(async (image) => {
    const index = createdImages.findIndex(
      (createdImage) => last(createdImage.image.split('.')) === image.type.split('/')[1],
    );
    const [newAdImage] = createdImages.splice(index, 1);

    // Check if the image size is larger than 10 MB
    if (image.size > SIZE_TO_COMPRESS && newAdImage.image_signed_url) {
      const compressedImage = await compressImage(image);
      if (compressedImage) {
        await uploadFileToS3(newAdImage.image_signed_url, compressedImage);
      }
    } else if (newAdImage.image_signed_url) {
      await uploadFileToS3(newAdImage.image_signed_url, image);
    }

    const thumbnail = await generateThumbnail(image);
    if (newAdImage.thumbnail_signed_url && thumbnail !== null) {
      await uploadFileToS3(newAdImage.thumbnail_signed_url, thumbnail);
    }
    savedImages.push(newAdImage);
  });

  await Promise.all(uploadPromises);

  return savedImages;
};

export const fetchAnnouncement = async (endpoint: EndpointFn, id: string) => {
  const { data } = await api.get(endpoint(id));
  return data;
};
