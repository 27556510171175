import { IAd } from '@/modules/ads';
import { getOpenGraph } from '@/modules/seo';
import useTranslation from 'next-translate/useTranslation';
import slugify from '@sindresorhus/slugify';
import { useCategories } from '@/modules/categories';
import { useTranslateLocation } from '@/modules/locations';
import { NextSeoProps } from 'next-seo';

export const useSeo = (ad: IAd, host: string): NextSeoProps => {
  const { t, lang } = useTranslation();
  const {
    category_codename: category,
    sub_category_codename: subcategory,
  } = ad;
  const { getCategoryByCode } = useCategories();

  const translatedLocation = useTranslateLocation(
    ad?.city?.codename,
    ad?.district?.codename,
  );

  const data = (() => {
    const title = t('seo:adPage.title', { adTitle: ad.title, place: translatedLocation });
    const description = t('seo:adPage.description', { adTitle: ad.title, place: translatedLocation });

    const categoryItem = getCategoryByCode(category);
    const subCategoryItem = getCategoryByCode(category, subcategory);

    const categoryPath = categoryItem?.codename;
    const subCategoryPath = subCategoryItem?.codename;
    const adSlug = ad.title ? slugify(ad.title) : null;

    const languageAlternates = ['ru', 'en'].map((hrefLang) => ({
      href: `${host}/${hrefLang}/adv/${categoryPath}${subCategoryPath ? `/${subCategoryPath}` : ''}/${adSlug}_${ad.id}`,
      hrefLang,
    }));
    const canonical = languageAlternates.find((item) => item.hrefLang === lang)?.href;

    const openGraph = getOpenGraph(ad, 'adv', lang, canonical);

    return {
      title,
      languageAlternates,
      canonical,
      description,
      openGraph,
    };
  })();

  return data;
};
