import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import type { IAd } from '@/modules/ads/types';
import { AdImage } from '@/modules/ads/components/ad-image';
import { AdLink } from '@/modules/ads/components/ad-link';
import { useTranslateLocation } from '@/modules/locations';
import useTranslation from 'next-translate/useTranslation';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import {
  AppTooltip,
  Button,
  Checkbox,
  SvgIcon,
} from '@/modules/shared/components';
import styles from './my-ad-card.module.scss';
import { ActionButtons } from './action-buttons.component';
import { ModalEdit } from './modal-edit.component';
import { ModalEditPrice, ModalSuccess } from './modal-edit-price';

interface MyAdCardProps {
  ad: IAd;
  adType: string;
  isSelected: number[];
  onSelect: (id: React.SetStateAction<number>) => void;
  onClick: (id: React.SetStateAction<number>) => void;
  isCompany: boolean;
}

export const MyAdCard = ({
  ad,
  adType,
  onSelect,
  isSelected,
  onClick,
  isCompany,
}: MyAdCardProps) => {
  const { t } = useTranslation('userProfile');
  const [modalOpen, setModalOpen] = useState<'edit' | 'edit_price' | 'success' | null>(null);
  const translateLocations = useTranslateLocation(
    ad?.city.codename,
    ad?.district?.codename,
  );
  const priceDisplayFormat = useFormatDataPrice();
  const handleEditModal = useCallback(
    () => setModalOpen((prevValue) => (prevValue === 'edit' ? null : 'edit')),
    [],
  );

  return (
    <>
      <div className={styles.my_ad_card}>
        {adType === 'active' && (
          <Checkbox
            checkboxSize="24"
            checked={!!isSelected?.includes(ad?.id)}
            onChange={() => onSelect(ad?.id)}
          />
        )}
        <div className={styles.card}>
          <div className={styles.image}>
            <AdLink ad={ad}>
              {ad.images[0] ? <AdImage ad={ad} /> : <SvgIcon name="b_no_bg" />}
            </AdLink>
          </div>
          <div className={styles.card_content}>
            <div
              className={clsx(styles.ad_info, {
                [styles.active_ads]: adType === 'active',
              })}
            >
              <div className={styles.title}>
                <AdLink ad={ad} className={styles.title}>
                  <AppTooltip title={ad.title}>
                    <h4>
                      {adType === 'drafts'
                        ? t('myAds.notSpecifiedTitle')
                        : ad?.title}
                    </h4>
                  </AppTooltip>
                </AdLink>
                <p>
                  {adType === 'drafts' ? (
                    t('myAds.notSpecifiedPrice')
                  ) : (
                    <>
                      {priceDisplayFormat({
                        priceType: ad?.price_type,
                        price: ad?.price,
                      })}
                      {ad?.price_type === 'for_money' && <SvgIcon name="THB" />}
                    </>
                  )}
                </p>
              </div>
              {adType !== 'drafts' && (
                <div className={styles.description}>
                  <p>{ad?.description}</p>
                </div>
              )}
              <div className={styles.location_date}>
                <span className={styles.city}>
                  <SvgIcon name="geo-point" />
                  <p>{translateLocations}</p>
                </span>
                {adType !== 'drafts' && (
                  <p className={styles.date}>{ad?.created_at}</p>
                )}
              </div>
            </div>
            <div className={styles.actions}>
              <ActionButtons
                adType={adType}
                adId={ad?.id}
                onClick={onClick}
                onEdit={handleEditModal}
                isCompany={isCompany}
              />
              {adType === 'drafts' && (
                <div className={styles.drafts_buttons}>
                  <Button
                    fullWidth
                    as="button"
                    buttonSize="xs"
                    buttonColor="grey"
                    appearance="primary"
                    className={styles.edit}
                  >
                    <SvgIcon name="pencil" />
                    {t('myAds.edit')}
                  </Button>
                  <Button
                    as="button"
                    buttonSize="xs"
                    buttonColor="grey"
                    appearance="primary"
                    className={styles.delete}
                    onClick={() => onClick(ad?.id)}
                  >
                    <SvgIcon name="trash-bin" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isCompany && (
        <ModalEdit
          isEditModalOpen={modalOpen === 'edit'}
          handleEditModal={handleEditModal}
          editPrice={() => setModalOpen('edit_price')}
          codenames={{
            id: ad.id,
            title: ad.title,
            category: ad.category_codename,
            subcategory: ad?.sub_category_codename,
            underSubcategory: ad?.under_sub_category_codename,
          }}
        />
      )}
      <ModalEditPrice
        open={modalOpen === 'edit_price'}
        onClose={() => setModalOpen(null)}
        onCancel={() => setModalOpen('edit')}
        id={ad.id}
        price={ad.price}
        price_type={ad.price_type}
        onSuccess={() => setModalOpen('success')}
      />
      <ModalSuccess
        open={modalOpen === 'success'}
        onClose={() => setModalOpen(null)}
        id={ad.id}
      />
    </>
  );
};
