import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import * as yup from 'yup';

const tokenSchema = yup.object({
  token_type: yup.string().oneOf(['access', 'refresh']).required(),
  exp: yup.number().integer().positive().required(),
  iat: yup.number().integer().positive().required(),
  jti: yup.string().required(),
  user_id: yup.number().integer().required(),
});

export const getJwtToken = (token = '') => {
  const decoded = jwt.decode(token);

  try {
    const jwtToken = tokenSchema.validateSync(decoded);
    const now = Math.floor(Date.now() / 1000);

    return {
      ...jwtToken,
      token,
      isExpired: Boolean(jwtToken.exp < now),
      willBeExpiredSoon: Boolean((jwtToken.exp - (3600 * 12)) < now),
    };
  } catch (e) {
    return null;
  }
};

export const getTokenExpiration = (token: string) => {
  const decoded = jwt.decode(token);

  if (typeof decoded !== 'string' && typeof decoded?.exp === 'number') {
    return new Date(decoded.exp * 1000);
  }

  return undefined;
};

export const AUTH_COOKIES = [
  'access_token',
  'refresh_token',
  'user_id',
  'forgotPasswordEmail',
  'resetPassword',
  'resetPassword2',
];

export const cleanCookies = () => {
  AUTH_COOKIES.forEach((el) => Cookies.remove(el));
};
