import { networks, profileBasePath } from '@/modules/shared/const';

export const PHONE_NUMBER = '+66 88 910 83 83';
export const EMAIL = 'support@bazaarorigin.com';
export const EMAIL_GMAIL = 'bazaarorigin@gmail.com';
export const regions = [
  { codename: 'phuket' },
  { codename: 'pattaya' },
  { codename: 'samui' },
  { codename: 'krabi' },
  { codename: 'pangan' },
];
export const RIGHTS = `© 2023-${new Date().getFullYear()} bazaar`;
export const companyLinks = ['about', 'faq', 'rules', 'privacy'];

export const media = [...networks];

export const UNSHOW_DEFAULT_FOOTER = [
  `/${profileBasePath}/personal-account`,
  `/${profileBasePath}/settings`,
  `/${profileBasePath}/rating`,
  `/${profileBasePath}/my-feedback`,
  `/${profileBasePath}/account`,
  `/${profileBasePath}/become-company`,
  '/events/addevents',
  `/${profileBasePath}/posters`,
  `/${profileBasePath}/favorites`,
  `/${profileBasePath}/chat`,
  '/support',
  '/support/form',
  `/${profileBasePath}/my-ads`,
  '/product/create',
  '/adv/edit/[...slug]',
]; // не показывать дефолтный футер на этих страницах
