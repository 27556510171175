import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';

export const SearchApi = {
  getSuggestions: async (search: string | undefined) => {
    const queryParams = new URLSearchParams();

    if (search) {
      queryParams.append('search', search);
    }

    const { data } = await api.get(endpoints.getSuggestions(queryParams.toString()));
    return data?.results || [];
  },
};
