import React from 'react';
import clsx from 'clsx';
import styles from './dots.module.scss';

interface DotsProps {
  count: number;
  activeIndex: number;
  onClick?: (index: number) => void;
  classNameContainer?: string;
  classNameDot?: string;
}

export const Dots: React.FC<DotsProps> = ({
  count, activeIndex, onClick, classNameContainer, classNameDot,
}) => (
  <div className={clsx(styles.dots, classNameContainer)}>
    {Array.from({ length: count }).map((_, index) => (
      <button
        key={index}
        className={clsx(styles.dot, { [styles.active]: activeIndex === index }, classNameDot)}
        onClick={() => onClick?.(index)}
        aria-label={`Go to slide ${index + 1}`}
      />
    ))}
  </div>
);
