import { fetchList, api } from '@/modules/shared/api';
import { Filters } from '@/modules/ads/components/filter/types';
import { endpoints } from './endpoints';
import { CategoryResponse, Category } from '../types';


const tempDisabledTransportSubCategories = ['rental', 'buy'];


export const CategoriesApi = {
  list: async (config = {}) => {
    const data = await fetchList<CategoryResponse[]>(endpoints.list, config);
    const tempFix = data.map((item) => {
      if (item.codename !== 'vehicles-rental') {
        return item;
      }

      return {
        ...item,
        sub_categories: item.sub_categories
          .filter((subCategory) => !tempDisabledTransportSubCategories.includes(subCategory.codename)),
      };
    });
    return { data: tempFix };
  },

  loadFilters: async (categoryId?: number, subCategoryId?: number, underSubCategoryId?: number): Promise<Filters> => {
    if (!categoryId) {
      return [{
        type: 'location' as const,
        params: { city: 'city', district: 'district' },
      }, {
        type: 'price' as const,
        params: { minPrice: 'min_price', maxPrice: 'max_price' },
      }, {
        name: 'announcement_source',
        type: 'checkbox_list' as const,
        choices: ['company', 'individual'],
      }];
    }
    const response = await api.get<{
      filters: Filters
    }>(underSubCategoryId
      ? endpoints.getUnderSubCategory(underSubCategoryId)
      : (subCategoryId ? endpoints.getSubCategory(subCategoryId)
        : endpoints.getCategory(categoryId)));

    return response.data.filters;
  },

  getMinMaxPriceValue: async (filters: Record<string, unknown>) => {
    const params = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value != 0) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params.append(key, item.toString());
          });
        } else {
          params.append(key, value.toString());
        }
      }
    });

    type PriceValue = number | 'for_free' | 'by_agreement' | undefined;

    const { data } = await api.get<{
      min_price: PriceValue;
      max_price: PriceValue;
    }>(`${endpoints.getMinMaxPrice()}?${params.toString()}`);

    const PRICE_MAP: Record<string, number | undefined> = {
      for_free: 0,
      by_agreement: undefined,
    };

    if (data.min_price === 'for_free' || data.min_price === 'by_agreement') {
      data.min_price = PRICE_MAP[data.min_price];
    }

    if (data.max_price === 'for_free' || data.max_price === 'by_agreement') {
      data.max_price = PRICE_MAP[data.max_price];
    }

    return {
      min_price: data.min_price?.toLocaleString(),
      max_price: data.max_price?.toLocaleString(),
    };
  },

  toplist: async (size: number): Promise<Category[]> => {
    const { data } = await api.get<Category[]>(endpoints.toplist(size));
    return data;
  },
};
