import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  list: () => `${baseEndpointV1}/admin_panel/category`,
  getCategory: (id: number) => `${baseEndpointV1}/admin_panel/category/${id}`,
  getSubCategory: (id: number) => `${baseEndpointV1}/admin_panel/subcategory/${id}`,
  getUnderSubCategory: (id: number) => `${baseEndpointV1}/admin_panel/undersubcategory/${id}`,
  toplist: (size: number) => `${baseEndpointV1}/admin_panel/categories/top/list/?size=${size}`,
  getMinMaxPrice: () => `${baseEndpointV1}/announcement/price-range/`,
};
