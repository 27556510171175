import slugify from '@sindresorhus/slugify';
import useTranslation from 'next-translate/useTranslation';
import { Button, Modal } from '@/modules/shared/components';
import styles from './modal-edit.module.scss';

interface ModalEditProps {
  isEditModalOpen: boolean;
  handleEditModal: () => void;
  editPrice: () => void;
  codenames: {
    id: number;
    title: string;
    category: string;
    subcategory: string | undefined;
    underSubcategory: string | undefined;
  };
}

export const ModalEdit = (props: ModalEditProps) => {
  const {
    isEditModalOpen,
    handleEditModal,
    editPrice,
    codenames,
  } = props;
  const { t } = useTranslation('userProfile');

  const generateAdLink = () => {
    const { category, subcategory, underSubcategory } = codenames;

    const title = slugify(codenames.title);
    const subcategoryPath = subcategory ? `/${subcategory}` : '';
    const underSubcategoryPath = underSubcategory ? `/${underSubcategory}` : '';

    return `/adv/edit/${category}${subcategoryPath}${underSubcategoryPath}/${title}_${codenames.id}`;
  };

  return (
    <Modal
      title={t('editModal.title')}
      open={isEditModalOpen}
      onClose={handleEditModal}
      classNameHeaderContainer={styles.header}
      className={styles.modal}
      titleJustifyContent="start"
      showBackButton={false}
      isLeftSlot={false}
    >
      <p className={styles.choose}>{t('editModal.text')}</p>
      <div className={styles.links}>
        <Button
          as="link"
          buttonSize="s"
          appearance="primary"
          buttonColor="grey"
          fullWidth
          className={styles.link}
          href={generateAdLink()}
        >
          {t('editModal.links.all')}
        </Button>
        <Button
          buttonSize="s"
          appearance="primary"
          buttonColor="grey"
          fullWidth
          className={styles.link}
          onClick={editPrice}
        >
          {t('editModal.links.price')}
        </Button>
      </div>
    </Modal>
  );
};
