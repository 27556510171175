import { type UserType } from '@/modules/users/types';

export enum OnboardingActionType {
  ROUTER_PUSH = 'ROUTER_PUSH',
}

type OnboardingButtonConfig = {
  label: string;
  actionType: OnboardingActionType;
  pathRouter?: { desktop: string; mobile?: string };
};

export type OnboardingButton = {
  done?: OnboardingButtonConfig;
};

type OnboardingStep = {
  stepIndex: number;
  title: string;
  description: string[];
  images: { desktop: string; mobile: string };
  button?: OnboardingButton;
};

export type ModalConfig = {
  id: string;
  priority: number;
  completionKey: string;
  userType: UserType;
  version: string;
  enabled: boolean;
  steps: OnboardingStep[];
};

export type ModalParams = {
  [key: string]: ModalConfig;
};
