import { ButtonHTMLAttributes } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button, SvgIcon } from '@/modules/shared/components';
import mobileStyles from './mobile-my-ad-card.module.scss';
import desktopStyles from './my-ad-card.module.scss';

type ButtonType = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>;

interface ButtonComponentProps extends ButtonType {
  color?: 'grey' | 'light_blue';
  className: string;
  iconName: string;
  text: string;
}

interface ActionButtonsProps {
  adType: string;
  adId: number;
  onClick: (id: number) => void;
  onEdit?: (id: number) => void;
  isCompany: boolean;
}

const ButtonComponent = (props: ButtonComponentProps) => {
  const {
    text,
    iconName,
    className,
    color = 'grey',
    ...rest
  } = props;

  const { isDesktop } = useMatchMedia();

  if (isDesktop) {
    return (
      <Button
        as="button"
        buttonSize="xs"
        appearance="primary"
        buttonColor={color}
        className={desktopStyles[className]}
        fullWidth
        {...rest}
      >
        <SvgIcon name={iconName} />
        {text && text}
      </Button>
    );
  }

  return (
    <button
      type="button"
      className={mobileStyles[className]}
      {...rest}
    >
      <SvgIcon name={iconName} />
    </button>
  );
};

// пока только для скрытых и активных объявлений, драфты можно перенести в будущем
export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    adId,
    adType,
    onEdit,
    onClick,
    isCompany,
  } = props;
  const { t } = useTranslation('userProfile');

  return (
    <>
      {adType === 'active' && (
        <ButtonComponent
          className="hide"
          onClick={() => onClick(adId)}
          text={t('myAds.hide')}
          iconName="eye-opened"
        />
      )}
      {adType === 'hidden' && (
        <ButtonComponent
          className="open"
          onClick={() => onClick(adId)}
          text={t('myAds.open')}
          iconName="eye-closed"
        />
      )}
      {adType !== 'drafts' && isCompany && (
        <ButtonComponent
          iconName="pencil"
          color="light_blue"
          className="main_edit"
          onClick={() => onEdit?.(adId)}
          text={t('myAds.edit')}
        />
      )}
    </>
  );
};
