import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import { EditAdTypes } from '../types';

const priceTypes: EditAdTypes['price_type'][] = ['for_money', 'for_free', 'by_agreement'];


export const useEditAdSchema = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t(
        'forms:validationErrors.isNotEmpty',
        { nameField: t('createAd:descriptionAndPhotos.adTitle') },
      ))
      .max(32, t('forms:validationErrors.maxLength', { maxLength: 32 })),

    description: yup
      .string()
      .required(t(
        'forms:validationErrors.isNotEmpty',
        { nameField: t('createAd:descriptionAndPhotos.description') },
      ))
      .max(10000, t('forms:validationErrors.maxLength', { maxLength: 10000 })),

    price: yup
      .string()
      .when('price_type', {
        is: (value: EditAdTypes['price_type']) => value === 'for_money',
        // eslint-disable-next-line @typescript-eslint/no-shadow
        then: (schema) => schema.required(t('createAd:errors.price')),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        otherwise: (schema) => schema.notRequired(),
      }),

    price_type: yup
      .string()
      .oneOf(priceTypes)
      .required(t('createAd:errors.price')),
  });

  return { schema };
};
