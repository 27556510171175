import React, {
  useState, useCallback, useRef, useEffect, useMemo,
} from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { SvgIcon } from '@/modules/shared/components';
import { useSwipeToCloseHook } from '@/modules/shared/hooks';
import { useSearchSuggestions } from '@/modules/search/queries';
import { SearchSuggestion } from './search-suggestion.component';
import { SearchInput } from './search-input.component';
import styles from './search-bar.module.scss';

export type SearchProps = {
  isInputFocused: boolean;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  keyHistory?: string;
  hasHistory?: boolean;
  onSearchFilter: (value: string) => void;
  text?: {
    placeholder: string;
    button?: string;
  };
  iconOnly?: boolean;
  routeToReset?: string;
  showSuggestions?: boolean; // Показывать ли подсказки
  hasSearchButton?: boolean; // Определяет, должна ли быть кнопка в Поиске
  customStyles?: {
    searchBar?: string;
    field?: string;
    button_delete?: string;
    searchBarContainer?: string;
    searchBarField?: string;
  };
  searchOnType?: boolean; // Отправлять ли запрос на каждый символ в поле поиска
  shouldClearSearchOnBack?: boolean; // Очищать ли поле поиска при нажатии на кнопку "назад" (для мобильной версии)
  isClickOutside?: boolean; // Закрывать ли поиск при клике вне поля поиска
  shouldClearOnRouteChange?: boolean; // Нужно ли сбрасывать поиск при смене роута
};

export const SearchBar: React.FC<SearchProps> = ({
  isInputFocused,
  setIsInputFocused,
  keyHistory,
  hasHistory = true,
  onSearchFilter,
  text,
  iconOnly = false,
  customStyles = {},
  routeToReset = '/',
  showSuggestions = false,
  hasSearchButton = true,
  searchOnType = false,
  shouldClearSearchOnBack = false,
  isClickOutside = true,
  shouldClearOnRouteChange = false,
}) => {
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const { data: suggestions } = useSearchSuggestions({
    search: showSuggestions && search ? search : '',
  });

  const currentValue = useMemo(() => {
    if (inputValue && inputValue !== search) return inputValue;
    if (search && search !== inputValue) return search;
    return inputValue || search;
  }, [search, inputValue]);

  useEffect(() => {
    if (keyHistory) {
      setSearchHistory(JSON.parse(localStorage.getItem(keyHistory) || '[]'));
    }
  }, [keyHistory]);

  useEffect(() => {
    const trimmedSearch = search.trim();
    if (router.pathname === '/[city]/posters' && trimmedSearch.length === 0) {
      onSearchFilter('');
    }
  }, [onSearchFilter, router.pathname, search]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === routeToReset) {
        setSearch('');
        setInputValue('');
        return;
      }

      if (shouldClearOnRouteChange) {
        if (!url.includes('/catalog') || !url.includes('search=')) {
          setSearch('');
          setInputValue('');
          return;
        }
      }

      const searchFromUrl = router.query.search;
      if (searchFromUrl) {
        setSearch(
          typeof searchFromUrl === 'string'
            ? decodeURIComponent(searchFromUrl)
            : searchFromUrl.map((v) => decodeURIComponent(v)).join(', '),
        );
      }
    };

    handleRouteChange(router.asPath);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, routeToReset, shouldClearOnRouteChange]);

  useEffect(() => {
    const handleBackButton = (e: PopStateEvent) => {
      if (isInputFocused) {
        setIsInputFocused(false);
        inputRef.current?.blur();
        e.preventDefault();
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isInputFocused, setIsInputFocused]);

  const onSearch = useCallback((searchInput: string) => {
    const trimmedValue = searchInput.trim().toLowerCase();

    if (searchInput !== '' && trimmedValue.length) {
      setSearchHistory((prevHistory) => {
        const updatedHistory = prevHistory.filter((item) => item !== trimmedValue);
        updatedHistory.unshift(trimmedValue);
        if (keyHistory) localStorage.setItem(keyHistory, JSON.stringify(updatedHistory));
        return updatedHistory;
      });
    }

    onSearchFilter(searchInput);
  }, [keyHistory, onSearchFilter]);

  const clearHistory = useCallback(() => {
    if (keyHistory) {
      localStorage.removeItem(keyHistory);
      setSearchHistory([]);
    }
  }, [keyHistory]);

  const handleIconClick = () => {
    setIsInputFocused(!isInputFocused);
    if (!isInputFocused) {
      inputRef.current?.focus();
    }
  };

  const handleButtonBackClick = useCallback(() => {
    setIsInputFocused(false);

    if (shouldClearSearchOnBack) {
      onSearch('');
      setSearch('');
      setInputValue('');
    }
  }, [shouldClearSearchOnBack]);


  const swipeRef = useSwipeToCloseHook(() => {
    setIsInputFocused(false);
    inputRef.current?.blur();
  }, 'left-to-right');

  return (
    <>
      {iconOnly && (
      <button onClick={handleIconClick}>
        <SvgIcon name="search" />
      </button>
      )}
      {(isInputFocused || !iconOnly) && (
        <div
          ref={swipeRef}
          className={clsx(
            styles.search_bar,
            { [styles.mobile_search_bar]: isInputFocused },
            customStyles.searchBarContainer,
          )}
        >
          <div className={clsx(styles.field, customStyles.searchBarField)} ref={inputWrapperRef}>
            <button type="button" className={styles.mobile_button_close} onClick={handleButtonBackClick}>
              <SvgIcon name="arrow-back" />
            </button>
            <SearchInput
              value={currentValue}
              setSearch={setSearch}
              setInputValue={setInputValue}
              onSearch={onSearch}
              inputRef={inputRef}
              setIsInputFocused={setIsInputFocused}
              text={text}
              hasSearchButton={hasSearchButton}
              customStyles={customStyles}
              searchOnType={searchOnType}
              isClickOutside={isClickOutside}
            />
          </div>
          {(hasHistory || showSuggestions) && (
            <SearchSuggestion
              isSearching={search.length > 0}
              suggestions={suggestions}
              history={searchHistory}
              onSearch={onSearch}
              setSearch={setSearch}
              setInputValue={setInputValue}
              inputRef={inputRef}
              isInputFocused={isInputFocused}
              setIsInputFocused={setIsInputFocused}
              clearHistory={clearHistory}
            />
          )}
        </div>
      )}
    </>
  );
};
