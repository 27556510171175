import { NextRequest, NextResponse } from 'next/server';
import { getJwtToken } from '../providers/auth.helpers';

export const silentTokenRefresh = (req: NextRequest) => {
  const someAccessToken = req.cookies.get('access_token')?.value;
  const validAccessToken = getJwtToken(someAccessToken);
  const validRefreshToken = getJwtToken(req.cookies.get('refresh_token')?.value);

  if (
    (validAccessToken && (validAccessToken.isExpired || validAccessToken.willBeExpiredSoon))
      || (!validAccessToken && validRefreshToken)
      || (!validAccessToken && someAccessToken)
  ) {
    const callback = encodeURIComponent(`${req.nextUrl.pathname}${req.nextUrl.search}`);

    return NextResponse.redirect(new URL(`/silent-token-refresh?cb=${callback}`, req.url));
  }
};
