import { FormState } from '@/modules/shared/hooks';
import { InputRange } from '@/modules/shared/components';

import { FiltersMap } from '../types';
import { FilterRow } from '../components/filter-row';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { withErrorBoundary } from '../hoc/withErrorBoundary';


type ColorFieldProps = {
  filter: FiltersMap['price'],
  form: FormState<'max_price' | 'min_price' | 'min_price_value' | 'max_price_value'>
};

export const PriceField = withErrorBoundary(withDynamicFieldFormGuard<ColorFieldProps>(({ form, t }) => (
  <FilterRow title={t('filters:price.title')}>
    <InputRange
      fullWidth
      valueFrom={form.value.min_price || ''}
      valueTo={form.value.max_price || ''}
      onChangeFrom={form.setter.min_price!}
      onChangeTo={form.setter.max_price!}
      minValue={form.value.min_price_value || ''}
      maxValue={form.value.max_price_value || ''}
    />
  </FilterRow>
)));
