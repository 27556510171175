import { useCallback, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { InputPrice, Switcher } from '@/modules/shared/components';
import type { PriceType } from '@/modules/shared/types';
import s from './modal-edit-price.module.scss';
import { PriceForm } from './types';

type NewPriceProps = {
  price_type: string | undefined;
};

export const NewPrice = ({ price_type }: NewPriceProps) => {
  const { t } = useTranslation();
  const {
    control, setValue, clearErrors, formState: { errors },
  } = useFormContext<PriceForm>();
  const priceValue = useWatch({ name: 'price' });

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/^[\d\b]+$/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }, []);

  const handleSwitch = useCallback((type: Omit<PriceType, 'for_money'>) => {
    setValue('price_type', type as PriceType);
    setValue('price', '');
    clearErrors('price');
    clearErrors('price_type');
  }, [clearErrors, setValue]);

  useEffect(() => {
    if (priceValue && Number(priceValue) === 0) {
      setValue('price_type', 'for_free');
      setValue('price', '');
    } else if (priceValue && Number(priceValue) > 0) {
      setValue('price_type', 'for_money');
    }
  }, [priceValue, setValue]);

  return (
    <div className={s.form__content__new_price}>
      <p className={s.header}>{t('userProfile:editPriceModal.newPrice')}</p>
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <InputPrice
            {...field}
            onChange={(e) => {
              field.onChange(e);
              setValue('price_type', 'for_money');
            }}
            onKeyDown={handleKeyDown}
          />
        )}
      />
      {price_type !== 'for_free' && (
        <div className={s.switcher}>
          <Controller
            name="price_type"
            control={control}
            render={({ field }) => (
              <Switcher
                {...field}
                checked={field.value === 'for_free'}
                onChange={() => handleSwitch('for_free')}
              />
            )}
          />
          <p>{t('common:forFree')}</p>
        </div>
      )}
      {price_type !== 'by_agreement' && (
        <div className={s.switcher}>
          <Controller
            name="price_type"
            control={control}
            render={({ field }) => (
              <Switcher
                {...field}
                checked={field.value === 'by_agreement'}
                onChange={() => handleSwitch('by_agreement')}
              />
            )}
          />
          <p>{t('common:byAgreement')}</p>
        </div>
      )}
      {(errors.price || errors.price_type) && (
        <p className={s.error}>{t('createAd:errors.price')}</p>
      )}
    </div>
  );
};
