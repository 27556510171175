import { QueryKey, UseQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { buildListQueryKeyFactory } from './helpers';
import { ChatApiListRequestConfig, IListRequestConfig } from '../api/helpers';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

type UseInfiniteListQueryOptions = {
  disabled?: boolean;
} & (IListRequestConfig | ChatApiListRequestConfig) & Omit<UseQueryOptions, 'queryKey'>;

type ListApiType = (config?: IListRequestConfig) => Promise<{ [key: string]: unknown }>;

type KeyFactoryType = (queryKey: QueryKey) => (options: IListRequestConfig) => unknown[];

type FetchFactoryType = (listApi: (config?: IListRequestConfig) => Promise<{
  [key: string]: unknown;
}>) => ({ pageParam, queryKey }: {
  pageParam: unknown;
  queryKey: unknown;
}) => Promise<{
  [key: string]: unknown;
}>;

export const fetchInfiniteListFactory = (listApi: ListApiType) => async ({ pageParam, queryKey }) => {
  const [filters, order, pageSize] = queryKey;
  return listApi({
    url: pageParam,
    ...(pageParam ? {} : { order, filters, pageSize }),
  });
};


const getNextPageParam = (lastPage) => {
  try {
    const url = new URL(lastPage.next);
    /**
     * Заново строим урл до апи с использованием хоста из API_URL
     * Если этого не делать, при работе через local-proxy.js будет проставляться хост после 301 редиректа
     */
    return `${API_URL}${url.pathname}${url.search}`;
  } catch (e) {
    return lastPage.next;
  }
};


/**
 *
 * @param {ListApiType} listApi
 * @param {string[]} baseQueryKey
 * @param {KeyFactoryType} keyFactory
 * @param {FetchFactoryType} fetchFactory
 */
export const infiniteListQueryFactory = (
  listApi: ListApiType,
  baseQueryKey: string[],
  keyFactory: KeyFactoryType = buildListQueryKeyFactory,
  fetchFactory: FetchFactoryType = fetchInfiniteListFactory,
) => {
  const fetchData = fetchFactory(listApi);
  const buildQueryKey = keyFactory(baseQueryKey);

  const useInfiniteListQuery = <T>(
    options: UseInfiniteListQueryOptions,
    initialData: { data: T[], count: number } = { data: [], count: 0 },
  ) => {
    const {
      disabled = false,
      refetchOnMount = false,
    } = options;
    const queryKey = buildQueryKey(options);

    const {
      data,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      error,
      refetch,
    } = useInfiniteQuery({
      queryKey,
      queryFn: fetchData,
      getNextPageParam,
      initialPageParam: undefined,
      enabled: !disabled,
      refetchOnMount,
      refetchOnWindowFocus: false,
      placeholderData: (prevData) => (initialData?.data.length ? (prevData ?? {
        pages: [initialData],
        pageParams: [undefined],
      }) : undefined),
    });

    return useMemo(() => ({
      hasMore: hasNextPage,
      isLoading: isFetchingNextPage || isLoading,
      fetchMore: fetchNextPage,
      pages: data?.pages || [],
      error,
      refetch,
    }), [
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      fetchNextPage,
      data?.pages,
      error,
      refetch,
    ]);
  };

  return useInfiniteListQuery;
};
