import { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Company } from '@/modules/company/types';
import { User } from '@/modules/users';
import {
  FloatNumber, UserAvatar, UserLabel, UserRating,
} from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { formatDateMonthYear, getDeclension } from '@/modules/shared/helpers';
import { profileBasePath } from '@/modules/shared/const';
import { useGetQrCode } from '@/modules/company/queries';
import type { ShareProfileModeType } from '../../../types';
import styles from './profile-summary.module.scss';


interface ProfileSummaryProps {
  companyData: Company;
  userData: User;
  mode: ShareProfileModeType;
  showReviews: () => void;
}

export const ProfileSummary = ({
  companyData, userData, mode, showReviews,
}: ProfileSummaryProps) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const [firstFlip, setFirstFlip] = useState(false);
  const { qrCode } = useGetQrCode(companyData.id);

  useEffect(() => {
    if (qrCode) setFirstFlip(true);
  }, [qrCode]);

  const averageRating = useMemo(() => getDeclension({
    count: userData?.reviews_received_count,
    t,
    singular: 'common:reviews.one',
    few: 'common:reviews.few',
    many: 'common:reviews.many',
  }), [userData, t]);

  const createdAt = useMemo(() => formatDateMonthYear({
    date: userData.created_at || '',
    t,
    translateMonth: 'common:months',
  }), [t, userData]);

  const hrefReviews = useMemo(
    () => (mode === 'view' ? `/companies/${companyData.id}` : `/${profileBasePath}/rating`),
    [mode],
  );

  return (
    <div className={styles.profile_summary}>
      <div className={styles.header}>
        <UserAvatar
          diameter={(isMobile || isTablet) ? '255px' : '100px'}
          srcAvatar={companyData?.logo?.thumbnail}
          userName={companyData.name}
          isAnimated
          firstFlip={firstFlip}
          qrCode={qrCode}
          style={{
            cursor: 'pointer',
            ...(isDesktop ? {} : { borderRadius: '100%' }),
          }}
        />
        <h2 className={styles.header_name}>{companyData.name}</h2>
        <UserLabel companyId={companyData.id} />

        <div className={styles.header_rating}>
          <div className={styles.header_rating__content}>
            <span className={styles.header_rating__number}>
              <FloatNumber
                value={userData.average_rating}
                precision={1}
                showRatingSuffix={false}
              />
            </span>
            <UserRating rating={userData.average_rating} sizeStar="24px" />
          </div>
          <Link
            shallow={mode === 'view'}
            href={hrefReviews}
            onClick={(e) => {
              if (mode === 'view') {
                e.preventDefault();
              }
              showReviews();
            }}
            className={styles.header_rating__reviews}
          >
            {averageRating}
          </Link>
        </div>
        <p className={styles.header_created}>
          {t('common:onBazaar')}
          {createdAt}
        </p>
      </div>

      <p className={styles.description}>{companyData.description}</p>
    </div>
  );
};
