import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import { ItemLoader, SvgIcon } from '@/modules/shared/components';
import {
  FORMATS, MAX_SIZE_BYTES, MAX_SIZE_PARAM, MIN_SIZE_BYTES, MIN_SIZE_PARAM,
} from '@/modules/shared/const';
import { isHeic, useConvertHeicToPng } from '@/modules/shared/helpers';
import { AddEventType } from '../../types';
import s from './add-event-page.module.scss';

export const Images = () => {
  const {
    register, watch, resetField, setValue, setError, formState: { errors }, clearErrors,
  } = useFormContext<AddEventType>();
  const { t } = useTranslation();
  const value = watch('images');
  const [image, setImage] = useState<File>();
  const convertHeicToPng = useConvertHeicToPng();
  const [preview, setPreview] = useState<string>();
  const [loading, setLoading] = useState(false);

  const resetImageState = () => resetField('images', { keepError: true });

  useEffect(() => {
    if (value && value[0]) {
      const splitted = value[0].name.split('.');
      const notValid = !FORMATS.includes(splitted[splitted.length - 1].toLowerCase());

      if (notValid) {
        setError('images', {
          type: 'format',
          message: t('forms:validationErrors.file_format'),
        });
        resetImageState();
        return;
      }
      if (value[0].size > MAX_SIZE_BYTES) {
        setError('images', {
          type: 'size',
          message: t('forms:validationErrors.photoExceedsMaxSize', MAX_SIZE_PARAM),
        });
        resetImageState();
        return;
      }
      if (value[0].size < MIN_SIZE_BYTES) {
        setError('images', {
          type: 'size',
          message: t('forms:validationErrors.photoBelowMinSize', MIN_SIZE_PARAM),
        });
        resetImageState();
        return;
      }
      clearErrors('images');

      const reader = new FileReader();
      reader.onload = function () {
        setLoading(true);
        const isHeicFile = isHeic(value[0]);
        if (isHeicFile) {
          convertHeicToPng(value[0])
            .then((file) => {
              const url = URL.createObjectURL(file ?? value[0]);
              setPreview(url);
              setImage(file ?? value[0]);
            })
            .catch((e) => console.error('Error converting HEIC to JPEG:', e))
            .finally(() => setLoading(false));
        } else {
          setPreview(URL.createObjectURL(value[0]));
          setImage(value[0]);
          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(value[0]);
    }
  }, [value]);

  useEffect(() => () => URL.revokeObjectURL(preview as string), []);

  const handleChange = () => {
    if (image) setValue('images', [image]);
  };

  const removeImage = () => {
    resetField('images');
    setImage(undefined);
    setError('images', {
      type: 'manual',
      message: t('forms:validationErrors.photo'),
    });
    URL.revokeObjectURL(preview as string);
  };

  return (
    <div className={s.field}>
      <h2>{t('events:createEvents.photos._')}</h2>
      <div className={s.field_inner}>
        <p>
          {t('events:createEvents.photos._')}
          <span> *</span>
        </p>
        <div className={s.values}>
          <p>
            {t('events:createEvents.photos.formats', { ...MIN_SIZE_PARAM, ...MAX_SIZE_PARAM })}
            {t('events:createEvents.photos.dimensions')}
          </p>
          <div className={s.images}>
            <div className={s.images_input_wrapper}>
              <label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .heic, .webp"
                  {...register('images', {
                    required: image ? false : t('forms:validationErrors.photo'),
                    onChange: handleChange,
                  })}
                  style={errors.images ? { border: '1px solid var(--bzr-secondary-error)' } : {}}
                />
                <SvgIcon name="add-photo-right-plus" />
              </label>
            </div>
            <div className={s.chosen_image}>
              {loading ? (
                <ItemLoader isLoading={loading} size={65} />
              ) : image && (
                <>
                  <img
                    src={preview}
                    alt="image"
                  />
                  <span onClick={removeImage}>
                    <SvgIcon name="close" />
                  </span>
                </>
              )}
            </div>
          </div>
          {errors.images && (
            <p className={s.error}>
              {errors.images['0'] as unknown as string || errors.images.message as string}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
