import React, { ReactNode } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { InputText } from '../input-text';
import styles from './input-range.module.scss';

interface InputRangeProps {
  fullWidth?: boolean
  valueFrom?: string;
  onChangeFrom: (value: string) => void;

  valueTo?: string;
  onChangeTo: (value: string) => void;
  fromPostfix?: ReactNode
  toPostfix?: ReactNode

  minValue?: string;
  maxValue?: string;
}

const defaultPostfix = <div />;

export const InputRange = ({
  valueTo,
  valueFrom,
  onChangeFrom,
  onChangeTo,
  fullWidth,
  fromPostfix = defaultPostfix,
  toPostfix = defaultPostfix,
  minValue,
  maxValue,
}: InputRangeProps) => {
  const { t } = useTranslation();
  const minValuePlaceholder = `${t('common:from').toLowerCase()} ${minValue ?? ''}`;
  const maxValuePlaceholder = `${t('common:to')} ${maxValue ?? ''}`;

  return (
    <div className={clsx(styles.wrapper, {
      [styles.wrapper_fullWidth]: fullWidth,
    })}
    >
      <div className={styles.inputs}>
        <InputText
          type="number"
          value={valueFrom}
          onChange={(e) => onChangeFrom(e.target.value || '')}
          placeholder={minValuePlaceholder}
          postfix={fromPostfix}
        />
        <InputText
          type="number"
          value={valueTo}
          onChange={(e) => onChangeTo(e.target.value || '')}
          placeholder={maxValuePlaceholder}
          postfix={toPostfix}
        />
      </div>
    </div>
  );
};
