import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CompanyApi } from '../api';
import { QR_CODE_KEY } from '../const';

export const useGetQrCode = (company_id: number) => {
  const [qrCode, setQrCode] = useState<string | null>(null);

  const { data, isSuccess } = useQuery({
    queryKey: [QR_CODE_KEY, company_id],
    queryFn: async () => {
      const response = await CompanyApi.getQrCode(company_id);
      return response;
    },
    enabled: Boolean(company_id),
  });

  useEffect(() => {
    if (isSuccess && data) {
      const objectUrl = URL.createObjectURL(data);
      setQrCode(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [isSuccess, data]);

  return { qrCode };
};
