import clsx from 'clsx';
import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Resolver,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Seo } from '@/modules/seo';
import {
  CreationTitle,
  StarNotification,
  CreationDescription,
} from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import partOfFormStyles from '../create-ad/components/part-of-form.module.scss';
import { PriceType } from '../create-ad';
import { IAd } from '../../types';
import {
  EditPrice,
  EditAdHeader,
  EditAdButtons,
  EditAdSuccessPage,
} from './components';
import { useEditAdMutation } from './mutations';
import { useEditAdSchema } from './hooks';
import { EditAdTypes } from './types';
import styles from './edit-ad.module.scss';


export const EditAd = ({ ad }: { ad: IAd }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { schema } = useEditAdSchema();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  useEffect(() => {
    const mobileNavbar = document.querySelector('#mobile-navigation-bar') as HTMLElement;

    if (!isDesktop && mobileNavbar) {
      const displayStyle = isSubmitSuccessful ? 'flex' : 'none';

      if (mobileNavbar.style.display !== displayStyle) {
        mobileNavbar.style.display = displayStyle;
      }
    }

    return () => {
      if (!isDesktop && mobileNavbar) {
        mobileNavbar.style.display = 'flex';
      }
    };
  }, [isDesktop, isSubmitSuccessful]);

  const formMethods = useForm<EditAdTypes>({
    resolver: yupResolver(schema) as Resolver<EditAdTypes>,
    defaultValues: {
      title: ad.title,
      description: ad.description,
      price: ad.price_type !== 'for_money'
        ? ''
        : ad.price.toString(),
      price_type: ad.price_type as PriceType,
    },
  });

  const {
    reset,
    setError,
    getValues,
    handleSubmit,
  } = formMethods;

  const { updateAd } = useEditAdMutation({
    adId: ad.id.toString(),
    reset,
    setError,
    getValues,
    setIsSubmitSuccessful,
  });

  const onSubmit: SubmitHandler<EditAdTypes> = async (data) => {
    const newData: Partial<EditAdTypes> = {};

    if (data.title !== ad.title) {
      newData.title = data.title;
    }
    if (data.description !== ad.description) {
      newData.description = data.description;
    }
    if (data.price !== ad.price.toString() && data.price_type === 'for_money') {
      newData.price = data.price;
    }
    if (data.price_type !== ad.price_type) {
      newData.price_type = data.price_type;
    }

    if (Object.keys(newData).length === 0) {
      return;
    }

    await updateAd({ id: ad.id.toString(), data: newData });
  };

  return (
    <>
      <Seo title={t('seo:editAdPage.title')} />
      {isSubmitSuccessful ? (
        <EditAdSuccessPage ad={ad} />
      ) : (
        <section
          className={clsx(
            partOfFormStyles.description_and_photos,
            styles.edit_ad_page,
          )}
        >
          <EditAdHeader />
          <div className={styles.edit_content}>
            <StarNotification />
            <form
              className={clsx(partOfFormStyles.block, styles.form)}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormProvider {...formMethods}>
                <div className={partOfFormStyles.block}>
                  <h3 className={partOfFormStyles.block_title}>
                    {t('edit-ad:subtitle')}
                  </h3>
                  <CreationTitle
                    title={t('edit-ad:form.adTitle')}
                    placeholder={t('edit-ad:form.adTitlePlaceholder')}
                  />
                  <CreationDescription />
                </div>
                <div className={partOfFormStyles.block}>
                  <h3 className={partOfFormStyles.block_title}>
                    {t('edit-ad:form.price')}
                  </h3>
                  <EditPrice />
                </div>
                <EditAdButtons ad={ad} />
              </FormProvider>
            </form>
          </div>
        </section>
      )}
    </>
  );
};
