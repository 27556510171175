import { profileBasePath } from '@/modules/shared/const';

export const NOT_DISPLAY_PATHS = [
  '/users',
  '/companies',
  `/${profileBasePath}`,
  '/product/create',
  '/poster',
  '/mobile',
  '/events/addevents',
  '/support/form',
  '/support',
  // для компании сделать отдельный константу в shared c уникальным url
  '/companies',
  '/adv/edit/[...slug]',
];

export const EVENT_DISPLAY_PATH = ['/poster'];
export const ADD_EVENT_PATH = '/events/addevents';
export const ADD_PRODUCT_PATH = '/product/create';

export const AD_SEARCH_KEY = 'search-history-ads';
export const EVENT_SEARCH_KEY = 'search-history-events';

export const UNSHOW_DEFAULT_HEADER = [
  '/adv',
  `/${profileBasePath}`,
  '/users',
  '/companies',
  '/events/addevents',
  '/poster/[...slug]',
  `/${profileBasePath}/chat`,
]; // не показывать дефолтный хедер на этих страницах

export const LINEAR_CATEGORIES_PATH = ['/users', '/companies'];

export const SHOULD_FIX_HEADER = [
  '/',
  '/adv/[...slug]',
  '/poster/[...slug]',
  '/[city]/posters',
  '/[city]/catalog/all',
  '/[city]/catalog/[category]',
  '/[city]/catalog/[category]',
  '/[city]/catalog/[category]/[subcategory]',
  '/[city]/catalog/[category]/[subcategory]/[undersubcategory]',
];

export const SHOULD_HIDE_SEARCHBAR = [
  '/poster/[...slug]',
];
