import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  UserAvatar,
  UserRating,
  UserCompanyRedirect,
  UserLabel,
} from '@/modules/shared/components';
import { formatDateMonthYear, getUserCompanyData } from '@/modules/shared/helpers';
import { UserData } from '@/modules/ads/types';
import styles from './ad-profile-block.module.scss';

export const AdProfileBlock = ({ data }: { data: UserData }) => {
  const { t } = useTranslation('');
  const {
    id,
    average_rating,
    created_at,
    ratings_count,
    company_id,
    company,
  } = data;

  const getReviewsTranslationKey = (count) => {
    if (count === 1) {
      return 'one';
    }
    if (count >= 2 && count <= 4) {
      return 'few';
    }
    return 'many';
  };

  const { name, avatar } = getUserCompanyData(company, data);

  return (
    <UserCompanyRedirect data={{ user: id, company: company_id }}>
      <div className={styles.container}>
        <div className={styles.avatar_and_contacts}>
          <div className={styles.avatar_wrapper}>
            <UserAvatar srcAvatar={avatar} userName={name} diameter="36px" />
          </div>

          <div className={styles.contact}>
            <p>{name}</p>
            <UserLabel companyId={company_id} view="mini" />
          </div>
        </div>
        <div className={styles.information}>
          <p className={styles.created}>
            {t('common:onBazaar')}
            {' '}
            <span>
              {formatDateMonthYear({ date: created_at, t, translateMonth: 'common:months' })}
            </span>
          </p>

          <div className={styles.user}>
            <div className={styles.user_block}>
              <span className={styles.user_rating}>{average_rating}</span>
              <UserRating rating={average_rating} sizeStar="16px" />
            </div>
            <p className={styles.user_reviews}>
              {`${t(`common:reviews.${getReviewsTranslationKey(ratings_count)}`, { count: ratings_count })}`}
            </p>
          </div>
        </div>
      </div>
    </UserCompanyRedirect>
  );
};
