import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import s from '@/styles/main/AuthModal.module.scss';
import useForgotPassword from '@/hooks/auth/useForgotPassword';
import useTranslation from 'next-translate/useTranslation';
import { useOpenAuthModal } from '../../hooks';
import { CodeInput } from './code-input.component';
import { ErrorMessage } from './error-message.component';
import { ActionButton } from './action-button.component';

export default function CodeModal() {
  const {
    control, handleSubmit, setValue, formState, setError, clearErrors,
  } = useForm();
  const { code, setCode, completeForgotPassword } = useForgotPassword();
  const setModalType = useOpenAuthModal();
  const { t } = useTranslation('');
  const {
    isSubmitting, isDirty, isValid, errors,
  } = formState;

  const handleCodeInput = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name.split('.')[1], 10);
    const inputChar = value.slice(-1);

    if (inputChar.match(/\d/)) {
      const newCode = [...code];
      newCode[index] = inputChar;
      setCode(newCode);
      setValue(`code.${index}`, inputChar, { shouldValidate: true });
      clearErrors('code'); // Clear errors when code changes
      if (index < 5) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }

      if (index === 5) {
        const currentInput = document.getElementById(`code-input-${index}`);
        if (currentInput) currentInput.blur();
      }
    }
  };

  const handleBackspace = useCallback((event) => {
    const { name, value } = event.target;
    const index = parseInt(name.split('.')[1], 10);

    if (event.keyCode === 8 || event.code === 'Backspace') {
      event.preventDefault();
      const newCode = [...code];

      if (value !== '') {
        newCode[index] = '';
        setValue(`code.${index}`, '', { shouldValidate: true });
      } else if (index > 0) {
        newCode[index - 1] = '';
        const previousInputName = `code.${index - 1}`;
        setValue(previousInputName, '', { shouldValidate: true });
        event.target.form[previousInputName].focus();
      }
      setCode(newCode);
      clearErrors('code'); // Clear errors when code changes
    }
  }, [code, setCode, setValue, clearErrors]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedData = (e.clipboardData || window.clipboardData).getData('text/plain').slice(0, 6);
    const newCode = [...code];

    pastedData.split('').forEach((char, i) => {
      newCode[i] = char;
    });
    setCode(newCode);

    newCode.forEach((char, i) => {
      const inputName = `code.${i}`;
      setValue(inputName, char, { shouldValidate: true });
    });
    clearErrors('code'); // Clear errors when code changes
  }, [code, setCode, setValue, clearErrors]);

  const onSubmit = async () => {
    try {
      await completeForgotPassword();
      setModalType('passwordSuccess');
    } catch (error) {
      const responseErrors = error.response.data?.validation_errors?.error_code;
      if (responseErrors.includes('INCORRECT_ACTIVATION_CODE')) {
        const index = responseErrors.indexOf('INCORRECT_ACTIVATION_CODE');
        setError('code', {
          type: 'manual',
          message: t(`auth:errors.${responseErrors[index]}`),
        });
      }
    }
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.enter_code}>
        <h2>{t('auth:forgotPassword.resetPasswordCode')}</h2>
        <p className={s.enter_code_description}>{t('auth:forgotPassword.receive')}</p>
        <form onSubmit={handleSubmit(onSubmit)} className={s.container}>
          <div>
            <CodeInput
              control={control}
              setValue={setValue}
              handleBackspace={handleBackspace}
              handleCodeInput={handleCodeInput}
              handlePaste={handlePaste}
            />
            <ErrorMessage error={errors.code} />
          </div>
          <ActionButton type="submit" disabled={isSubmitting || (isDirty && !isValid)}>
            {isSubmitting ? t('forms:labels.submitting') : t('auth:confirm')}
          </ActionButton>
        </form>
      </div>
    </div>
  );
}
