import React from 'react';
import { NextPage } from 'next';
import type { AppContext } from 'next/app';
import type { Me } from '@/modules/me/types';
import { MeApi } from '@/modules/me/api';
import { createRequestContext, bindContext } from '@/modules/shared/api';

export const withPreloadedAuth = (Component: NextPage) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = async (app: AppContext) => {
    const serverCtx = createRequestContext(app.ctx);
    const pageProps = await Component.getInitialProps?.(app.ctx);
    let me: Me | undefined;

    if (serverCtx.accessToken) {
      try {
        me = await bindContext(MeApi.getMe, serverCtx)();
      } catch (err) {
        console.error(err);
        serverCtx.accessToken = null;
      }
    }

    return { ...pageProps, accessToken: serverCtx.accessToken, me };
  };

  Wrapper.displayName = `withPreloadedAuth(${Component.displayName})`;
  return Wrapper;
};
