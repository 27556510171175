import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import Cookies from 'js-cookie';
import { useFeatureFlag } from '@/modules/shared/feature-flag';
import { useMeContext } from '@/modules/me';
import { UserTypeEnum } from '@/modules/users/types';
import { OnboardingModal } from '../components';
import { ONBOARDING_FEATURE_FLAG_KEY } from '../consts';
import { type ModalParams } from '../type';

interface OnboardingContextProps {
  closeOnboarding: (modalId?: string) => void;
  activeModals: string[];
  params: ModalParams;
}

const OnboardingContext = createContext<OnboardingContextProps | undefined>(undefined);

export const OnboardingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { me: meData } = useMeContext();

  const [enabledFeatureFlag, rawParams] = useFeatureFlag(ONBOARDING_FEATURE_FLAG_KEY);
  const params = rawParams as ModalParams;

  const modalsToShow = useMemo(() => {
    if (!enabledFeatureFlag || !params || meData === undefined || typeof params !== 'object') {
      return [];
    }

    return Object.values(params)
      .filter(({
        userType, completionKey, version, enabled,
      }) => {
        if (!enabled) return false;

        if (!(meData?.user_type === UserTypeEnum.Company || userType === UserTypeEnum.Individual)) {
          return false;
        }

        const storedData = Cookies.get(`onboarding_${completionKey}`);
        const storedVersion = storedData ? JSON.parse(storedData).version : null;

        return storedVersion !== version;
      })
      .sort((a, b) => a.priority - b.priority)
      .map(({ id }) => id);
  }, [meData]);

  const [activeModals, setActiveModals] = useState<string[]>(modalsToShow);

  useEffect(() => {
    setActiveModals(modalsToShow);
  }, [modalsToShow]);

  const closeOnboarding = (modalId?: string) => {
    if (modalId) {
      const modal = params?.[modalId];
      if (modal?.completionKey && modal?.version) {
        Cookies.set(`onboarding_${modal.completionKey}`, JSON.stringify({ version: modal.version }), { expires: 365 });
      }

      setActiveModals((prev) => prev.filter((id) => id !== modalId));
    } else {
      setActiveModals([]);
    }
  };

  return (
    <OnboardingContext.Provider value={{ closeOnboarding, activeModals, params }}>
      {children}

      {activeModals.length > 0 && params[activeModals[0]] ? (
        <OnboardingModal
          key={activeModals[0]}
          config={params[activeModals[0]]}
          closeOnboarding={() => closeOnboarding(activeModals[0])}
        />
      ) : null}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
