import { useRouter } from 'next/navigation';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import { MobileHeader } from '@/modules/app/components/header';
import { profileBasePath } from '@/modules/shared/const';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from '../edit-ad.module.scss';

export const EditAdHeader = () => {
  const { t } = useTranslation();
  const { back, push } = useRouter();
  const { isDesktop } = useMatchMedia();

  return (
    <>
      {isDesktop ? (
        <div className={styles.header}>
          <h1 className={styles.title}>{t('edit-ad:title')}</h1>
          <button
            type="button"
            className={styles.button_back}
            onClick={back}
          >
            <SvgIcon name="arrow-forward-left" />
          </button>
        </div>
      ) : (
        <MobileHeader
          title={t('edit-ad:title')}
          showBackButton
          onClickBack={back}
          onClose={() => push(`/${profileBasePath}/my-ads?tab=active`)}
        />
      )}
    </>
  );
};
