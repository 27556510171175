import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SearchApi } from '../api';
import { SEARCH_SUGGESTIONS } from '../consts';

interface SearchSuggestionsProps {
  search?: string;
}

export const useSearchSuggestions = ({
  search,
}: SearchSuggestionsProps) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [SEARCH_SUGGESTIONS, search],
    queryFn: () => SearchApi.getSuggestions(search),
    initialData: [],
    enabled: !!search,
    placeholderData: () => queryClient.getQueryData([SEARCH_SUGGESTIONS]) || [],
  });
};
