import { NextSeo } from 'next-seo';
import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import type { IAd } from '@/modules/ads/types';
import { category as categoryList } from './canonical-catalog.const';
// import styles from './seo.module.scss';

type SeoCatalogProps = {
  ads: IAd[],
  baseUrl: string;
};

export const SeoCatalog = ({ ads, baseUrl }: SeoCatalogProps) => {
  const { t: translateSeo } = useTranslation('seo-catalog');
  const { t: translateCity } = useTranslation('seo.common');
  const router = useRouter();

  const path = router.asPath.split('?')[0]; // на всякий случай убираем query параметры

  // когда добавится поддержка страны, добавить в начало country
  // Name и добавить страну в currentCity
  const [, cityName, , category, subCategory, underSubCategory] = path.split('/');

  const currentCity = useMemo(
    () => translateCity(`countries.th.${cityName}`),
    [cityName, translateCity],
  );

  const flowerDeliveryCity = useMemo(
    () => translateCity(`flower-delivery.th.${cityName}`),
    [cityName, translateCity],
  );

  const categoryTitle = `category.${category}.title`;
  const categoryDescription = `category.${category}.description`;
  const categoryCanonical = `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryList[category]?.title}`;

  const subCategoryTitle = `category.${category}.${subCategory}.title`;
  const subCategoryDescription = `category.${category}.${subCategory}.description`;

  const underSubCategoryTitle = `category.${category}.${subCategory}.${underSubCategory}.title`;
  const underSubCategoryDescription = `category.${category}.${subCategory}.${underSubCategory}.description`;

  const seoConfig = useMemo(() => {
    if (category === 'services' && subCategory === 'flower-delivery') {
      const categoryText = categoryList[category]?.title || category;
      const subCategoryText = categoryList[category]?.[subCategory]?.title || subCategory;
      return {
        title: translateSeo(subCategoryTitle, { city: currentCity }),
        description: translateSeo(subCategoryDescription, { city: currentCity, descriptionEnding: flowerDeliveryCity }),
        canonical: `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryText}/${subCategoryText}`,
      };
    }
    if (category && !subCategory && !underSubCategory) {
      return {
        title: translateSeo(categoryTitle, { city: currentCity }),
        description: translateSeo(categoryDescription, { city: currentCity }),
        canonical: categoryCanonical,
      };
    }
    if (subCategory && !underSubCategory) {
      const categoryText = categoryList[category]?.title || category;
      const subCategoryText = categoryList[category]?.[subCategory]?.title || subCategory;
      return {
        title: translateSeo(subCategoryTitle, { city: currentCity }),
        description: translateSeo(subCategoryDescription, { city: currentCity }),
        canonical: `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryText}/${subCategoryText}`,
      };
    }
    if (category && subCategory && underSubCategory) {
      const categoryText = categoryList[category].title || category;
      const subCategoryText = categoryList[category][subCategory].title || subCategory;
      const underSubCategoryText = categoryList[category][subCategory][underSubCategory]?.title || underSubCategory;
      return {
        title: translateSeo(underSubCategoryTitle, { city: currentCity }),
        description: translateSeo(underSubCategoryDescription, { city: currentCity }),
        canonical: `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryText}/${subCategoryText}/${underSubCategoryText}`,
      };
    }
  }, [
    category,
    subCategory,
    underSubCategory,
    translateSeo,
    subCategoryTitle,
    currentCity,
    subCategoryDescription,
    flowerDeliveryCity,
    baseUrl,
    router.locale,
    cityName,
    categoryTitle,
    categoryDescription,
    categoryCanonical,
    underSubCategoryTitle,
    underSubCategoryDescription,
  ]);

  const openGraph = useMemo(() => (
    {
      type: 'website',
      site_name: 'Bazaar',
      title: seoConfig?.title,
      description: seoConfig?.description,
      url: seoConfig?.canonical,
      images: ads?.map((el, i) => {
        const mainImg = el.images?.find((img) => img.id === el.main_picture);
        const url = mainImg?.thumbnail || mainImg?.image || '';
        return {
          url,
          alt: `Search item ${i + 1}`,
          secureUrl: url,
          width: 1200,
          height: 630,
          type: `image/${url?.split('.')?.at(-1) || ''}`,
        };
      }),
    }
  ), [seoConfig, ads]);

  const itemListJsonLd = useMemo(() => JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: ads?.map((el) => {
        const mainImg = el.images?.find((img) => img.id === el.main_picture);
        return {
          '@type': 'Product',
          name: el.title,
          brand: el.specifications?.brand ?? undefined,
          image: mainImg?.thumbnail || mainImg?.image || '',
          description: el.description,
          offers: {
            '@type': 'Offer',
            price: el.price_type === 'for_money' ? el.price : 0,
            priceCurrency: 'THB',
          },
          aggregateRating: el.user_data?.ratings_count ? {
            ratingValue: el.user_data?.average_rating,
            reviewCount: el.user_data?.ratings_count,
          } : {},
        };
      }),
    },
  ), [ads]);

  return (
    <>
      <NextSeo {...seoConfig} openGraph={openGraph} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: itemListJsonLd }}
      />
      {/* <h1 className={styles.h1}>{seoConfig?.title}</h1> */}
    </>
  );
};
