import { NextSeoProps } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import slugify from '@sindresorhus/slugify';
import { getEventSeoProps, getOpenGraph } from '@/modules/seo';
import { useTranslateLocation } from '@/modules/locations';
import { IEvent } from '../types';

export const useEventSeo = (event: IEvent, host: string): NextSeoProps => {
  const { t, lang } = useTranslation();

  const translatedLocation = useTranslateLocation(
    event?.city?.codename,
    event?.district?.codename,
  );

  const data = (() => {
    const eventSlug = event.title ? slugify(event.title) : null;

    const languageAlternates = ['ru', 'en'].map((hrefLang) => ({
      href: `${host}/${hrefLang}/poster/${eventSlug ? `${eventSlug}_${event.id}` : event.id}`,
      hrefLang,
    }));
    const canonical = languageAlternates.find((item) => item.hrefLang === lang)?.href;

    const { title, description } = getEventSeoProps(t, event, translatedLocation);

    const openGraph = getOpenGraph(event, 'poster', lang, canonical);

    return {
      languageAlternates,
      canonical,
      title,
      description,
      openGraph,
    };
  })();

  return data;
};
