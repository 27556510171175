import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Modal } from '@/modules/shared/components';
import { useUpdateAdMutation } from '@/modules/ads/mutations';
import {
  ADS_LIST_QUERY_KEY,
  INFINITE_LIST_QUERY_KEY,
  MY_ADS_INFINITY_LIST_QUERY_KEY,
  MY_ADS_LIST_QUERY_KEY,
} from '@/modules/ads/consts';
import s from './modal-edit-price.module.scss';
import { ModalEditPriceProps, PriceForm } from './types';
import { ActionButtons } from './action-buttons.component';
import { OldPrice } from './old-price.component';
import { NewPrice } from './new-price.component';

export const ModalEditPrice = ({
  open, onClose, onCancel, id, price, price_type, onSuccess,
}: ModalEditPriceProps) => {
  const { t } = useTranslation();
  const {
    mutate: update, isPending, isError, isSuccess,
  } = useUpdateAdMutation([
    [INFINITE_LIST_QUERY_KEY],
    [ADS_LIST_QUERY_KEY],
    [MY_ADS_LIST_QUERY_KEY],
    [MY_ADS_INFINITY_LIST_QUERY_KEY],
  ]);

  const formMethods = useForm<PriceForm>({ shouldUnregister: true });
  const { handleSubmit, setError } = formMethods;

  const onsubmit: SubmitHandler<PriceForm> = async (data) => {
    const body: PriceForm = { price_type: data.price_type };
    if (data.price) body.price = data.price;
    update({ id: `${id}`, body });
  };

  useEffect(() => {
    if (isError) {
      setError('price', t('createAd:errors.price'));
    }

    if (isSuccess) onSuccess();
  }, [isSuccess, isError]);

  return (
    <Modal
      open={open}
      title={t('userProfile:editPriceModal.title')}
      onClose={onClose}
      titleJustifyContent="start"
      showBackButton={false}
      isLeftSlot={false}
      className={s.modal}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onsubmit)}
          autoComplete="off"
          id="form-become-company"
          className={s.form}
        >
          <div className={s.form__content}>
            <OldPrice price={price} price_type={price_type} />
            <NewPrice price_type={price_type} />
          </div>
          <ActionButtons onCancel={onCancel} isPending={isPending} />
        </form>
      </FormProvider>
    </Modal>
  );
};
