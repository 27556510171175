import Cookie from 'js-cookie';
import { api, bindContext, uploadFileToS3 } from '@/modules/shared/api';
import { generateThumbnail, isServerSide } from '@/modules/shared/helpers';
import { endpoints } from './endpoints';
import {
  Me, IUpdateUserData, Avatar, OperationType,
} from '../types';

export const MeApi = {
  async getMe(headers?: Record<string, string>) {
    const accessToken = Cookie.get('access_token');

    if (!accessToken && !isServerSide()) {
      throw new Error('Access token is missing');
    }

    const { data } = await (bindContext(api.get<Me>, this))(endpoints.me(), { headers });
    return data;
  },
  updateUserData: async (newUserData: IUpdateUserData) => {
    const { data } = await api.patch(endpoints.me(), newUserData);
    return data;
  },
  updateUserAvatar: async (image: File, extension: string) => {
    try {
      const newAvatar: Avatar[] = [];
      const { data } = await api.post<Avatar>(endpoints.uploadAvatar(), { format: extension });
      const thumbnail = await generateThumbnail(image);
      await uploadFileToS3(data.thumbnail_signed_url as string, thumbnail, true);
      await uploadFileToS3(data.avatar_signed_url, image, true);
      newAvatar.push(data);

      return newAvatar;
    } catch (e) {
      console.error(e);
    }
  },

  updateUserLanguage: async (updateLanguage: string) => {
    const { data } = await api.patch(
      endpoints.me(),
      { locale: updateLanguage },
    );
    return data;
  },

  getVerificationCode: async (
    { operation_type = 'update', phone_number }:
    { phone_number: string, operation_type?: OperationType },
  ) => {
    const { data } = await api.post(
      endpoints.code(),
      { operation_type, phone_number },
    );
    return data;
  },

  updatePhoneNumber: async (
    { operation_type = 'update', phone_number, totp }: {
      phone_number: string, totp: string, operation_type?: OperationType,
    },
  ) => {
    const { data } = await api.put(
      endpoints.phone_number(),
      { operation_type, phone_number, totp },
    );
    return data;
  },

  verifyPhoneNumber: async ({
    operation_type = 'verification',
    phone_number,
    totp,
    isPostRequest,
  }: {
    phone_number: string,
    totp: string,
    operation_type?: OperationType,
    isPostRequest?: boolean,
  }) => {
    const { data } = isPostRequest
      ? await api.post(endpoints.phone_number(), { operation_type, phone_number, totp })
      : await api.put(endpoints.phone_number(), { operation_type, phone_number, totp });
    return data;
  },

  addEmail: async (email: string) => {
    const { data } = await api.post(endpoints.email(), { email });
    return data;
  },

  confirmPassword: async (password: string) => {
    const { data } = await api.post(endpoints.confirm_password(), { password });
    return data;
  },

  registerDevice: (
    body: Record<string, string> | null,
  ) => api.post(endpoints.registerDevice(), body),
};
