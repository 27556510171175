import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
} from 'react-hook-form';
import { useNetworkState } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { updateQueriesByKeys } from '@/modules/shared/helpers';
import { saveEntityImages } from '@/modules/shared/api';
import { EntityImage, ErrorResponse } from '@/modules/shared/types';
import { useTrackEvent } from '@/modules/events-tracking';
import { AdsApi } from '../../../api';
import {
  ADS_LIST_QUERY_KEY,
  INFINITE_LIST_QUERY_KEY,
  MY_ADS_LIST_QUERY_KEY,
  MY_ADS_INFINITY_LIST_QUERY_KEY,
} from '../../../consts';
import { useCreateAdMutation } from '../../../mutations';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';
import { CreateAdTypes } from '../types';


export const useSubmitAd = (
  reset: UseFormReset<CreateAdTypes>,
  setError: UseFormSetError<CreateAdTypes>,
  getValues: UseFormGetValues<CreateAdTypes>,
) => {
  const { t } = useTranslation();
  const { online } = useNetworkState();
  const { images: selectedImages } = useCreateAdContext();
  const { setMainStep, setSubmitting, resetAlmostAllContext } = useCreateAdActionsContext();

  const create = useCreateAdMutation([
    [INFINITE_LIST_QUERY_KEY],
    [ADS_LIST_QUERY_KEY],
    [MY_ADS_LIST_QUERY_KEY],
    [MY_ADS_INFINITY_LIST_QUERY_KEY],
  ]);
  const [retryData, setRetryData] = useState<CreateAdTypes | null>(null);
  const [uploadedImages, setUploadedImages] = useState<EntityImage[] | null>(null);

  const trackEvent = useTrackEvent();
  const queryClient = useQueryClient();

  const createImageApi = useCallback(
    (formats) => AdsApi.createImages({ formats }),
    [],
  );

  const createAd = async (data: CreateAdTypes) => {
    try {
      setSubmitting(true);

      let images = uploadedImages;
      if (!images) {
        // если картинки еще не были отправлены - отправляем
        const savedImages = await saveEntityImages(selectedImages, createImageApi);
        images = savedImages.sort((a, b) => a.id - b.id);
        setUploadedImages(images); // сохраняем ссылки на загруженные изображения
      }
      data.images = images;

      create.mutate(data, {
        onSuccess: async () => {
          trackEvent('publishAd');
          setMainStep(5);

          resetAlmostAllContext(); // очищаем контекст, кроме mainStep
          reset();
          setUploadedImages(null);
          setRetryData(null); // удаляем данные для повторной отправки

          await updateQueriesByKeys(
            queryClient,
            [ADS_LIST_QUERY_KEY, INFINITE_LIST_QUERY_KEY, 'me'],
            [MY_ADS_INFINITY_LIST_QUERY_KEY, MY_ADS_LIST_QUERY_KEY],
          );
        },
        onError: (error: AxiosError<ErrorResponse>) => {
          console.error('Product creation failed:', error);

          if (error.response?.data) {
            const currentValues = getValues();
            /**
             * FIXME:
             * когда форма отправляется, isSubmitSuccessful становится true и блокирует кнопку отправки формы в
             * компоненте src/modules/ads/components/create-ad/components/buttons.component.tsx даже если форма
             * отправилась с ошибкой. Если сделать state и устанавливать его в true в случае успешной отправки
             * некорректно блокирует кнопку, есть 1-2 секунды когда кнопка активна и можно отправить объявление еще раз
            * */
            reset({ ...currentValues }, { keepErrors: true });

            Object.entries(error.response.data.validation_errors).forEach(
              ([field, errorDetails]) => {
                setError(field as keyof CreateAdTypes, {
                  type: 'manual',
                  message: t(`forms:validationErrors.${errorDetails.code}`),
                });
              },
            );
          }
        },
      });
    } catch (error) {
      console.error('Product creation failed:', error);

      if (!error.response) {
        setRetryData(data); // сохраняем данные для повторной отправки при восстановлении сети
        setError('root', {
          type: 'manual',
          message: t('createAd:noInternet'),
        }); // устанавливаем ошибку на форму, чтобы isSubmitSuccessful не стал true
        return;
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (retryData && online) {
      createAd(retryData)
        .then(() => setRetryData(null))
        .catch((error) => console.error('Retry failed: ', error));
    }
  }, [online, retryData]);

  return {
    createAd,
  };
};
