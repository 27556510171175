import axios, {
  AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import qs from 'qs';
import { getTokenExpiration } from '@/modules/auth/providers/auth.helpers';
import Cookie from 'js-cookie';
import { IncomingMessage } from 'http';
import { onNetworkError, isServerSide, getServerSideCookieValue } from '../helpers';

const baseApiParams = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
};
const clientApi = axios.create(baseApiParams);

const getNetworkError = (err: AxiosError<{ detail?: string; message?: string }>) => {
  const message = err.response?.data?.detail || err.response?.data?.message || err.response?.statusText;
  return `Network error happened: ${message || err}`;
};

const authApi = axios.create(baseApiParams);
const onError = async (error: Error | AxiosError, originalRequest: AxiosRequestConfig) => {
  console.error(error);
  if (error instanceof AxiosError && error.response?.status === 401) {
    const refreshToken = Cookie.get('refresh_token');
    if (refreshToken) {
      try { // не получится использовать AuthApi.refreshAccessToken() из-за циклических зависимостей
        // we use authApi, because using clientApi will cause a cycle, ie it will call the same interceptor
        const { data } = await authApi.post<{ access: string }>(
          'api/users/refresh_token/',
          { refresh: refreshToken },
        );
        if (data.access) {
          const exp = getTokenExpiration(data.access);
          Cookie.set('access_token', data.access, { expires: exp });
        }
        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          return await clientApi(originalRequest);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          Cookie.remove('access_token');
          Cookie.remove('refresh_token');
          Cookie.remove('user_id');
          window.location.reload();
        }
        throw err;
      }
    }
  }
  onNetworkError(getNetworkError)(error);
  throw error;
};

// Request interceptor for API calls
clientApi.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (!newConfig.headers) {
      newConfig.headers = new AxiosHeaders({
        'Content-Type': 'application/json',
      });
    }
    const accessToken = Cookie.get('access_token');
    if (accessToken && typeof newConfig.headers.Authorization !== 'string') {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  },
  async (error) => {
    const originalRequest = error.config;
    return onError(error, originalRequest);
  },
);

// Response interceptor for API calls
clientApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => onError(error, error.config),
);

const createServerApiClient = (context?: RequestContext) => {
  const client = {
    ...axios.create({
      baseURL: process.env.API_URL || process.env.NEXT_PUBLIC_API_URL,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  } as AxiosInstance & {
    clone: typeof createServerApiClient
  };

  client.clone = createServerApiClient;
  client.interceptors.request.use(
    ((token?: string | null) => (config) => {
      const newConfig = { ...config };
      if (!newConfig.headers) {
        newConfig.headers = new AxiosHeaders({
          'Content-Type': 'application/json',
        });
      }
      if (token && !newConfig.headers.Authorization) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
      return newConfig;
    })(context?.accessToken),
  );

  return client;
};

export const createRequestContext = (ctx: {
  req?: IncomingMessage
}) => {
  if (!isServerSide()) return {};

  const { req } = ctx;
  const cookieString = req?.headers.cookie || '';
  const accessToken = getServerSideCookieValue(cookieString, 'access_token');

  return {
    req: ctx.req,
    accessToken,
  };
};
export type RequestContext = ReturnType<typeof createRequestContext> | undefined;

export const bindContext = <
    T extends (...params: unknown[]) => unknown,
>(fn: T, ctx: RequestContext) => fn.bind(ctx) as T;


const serverApi = {
  ...createServerApiClient(),
  post(this: RequestContext, ...args) {
    return serverApi.clone(this).post(...args);
  },
  put(this: RequestContext, ...args) {
    return serverApi.clone(this).put(...args);
  },
  patch(this: RequestContext, ...args) {
    return serverApi.clone(this).patch(...args);
  },
  delete(this: RequestContext, ...args) {
    return serverApi.clone(this).delete(...args);
  },
  get(this: RequestContext, ...args) {
    return serverApi.clone(this).get(...args);
  },
} as ReturnType<typeof createServerApiClient>;

export const api = isServerSide() ? serverApi : clientApi;
