import React, {
  FC, RefObject, useCallback, useEffect, useMemo, useState,
} from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { stopEvent } from '@/modules/shared/helpers';
import useTranslation from 'next-translate/useTranslation';
import styles from './search-suggestion.module.scss';

interface SearchProps {
  isSearching?: boolean;
  history: string[];
  suggestions?: string[];
  onSearch: (value: string) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  inputRef: RefObject<HTMLInputElement>;
  isInputFocused: boolean;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  clearHistory: () => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const List = ({
  items,
  iconName,
  onItemClick,
  selectedIndex,
}: {
  items: string[];
  iconName: string;
  selectedIndex: number;
  onItemClick: (item: string) => void;
}) => (
  <ul className={styles.history_list}>
    {items.map((item, index) => (
      <li
        key={index}
        onClick={() => onItemClick(item)}
        className={index === selectedIndex ? styles.selected : ''}
      >
        <SvgIcon name={iconName} style={{ fontSize: '24px' }} />
        {item}
      </li>
    ))}
  </ul>
);

export const SearchSuggestion: FC<SearchProps> = ({
  isSearching,
  history,
  onSearch,
  setSearch,
  inputRef,
  isInputFocused,
  setIsInputFocused,
  clearHistory,
  suggestions,
  setInputValue,
}) => {
  const { t } = useTranslation('common');
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const recentHistory = useMemo(() => [...history].slice(0, 5), [history]);
  const items = useMemo(() => (isSearching ? suggestions : recentHistory), [isSearching, suggestions, recentHistory]);

  const handleSuggestionClick = useCallback((item: string) => {
    setSearch(item);
    setIsInputFocused(false);
    inputRef.current?.blur();
    onSearch(item);
  }, [inputRef, onSearch, setIsInputFocused, setSearch]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!items || !items.length || !isInputFocused) return;

      if (e.key === 'ArrowDown') {
        setSelectedIndex((prev) => {
          const nextIndex = prev < items.length - 1 ? prev + 1 : 0;
          setInputValue(items[nextIndex]);
          e.preventDefault();
          return nextIndex;
        });
      } else if (e.key === 'ArrowUp') {
        setSelectedIndex((prev) => {
          const nextIndex = prev > 0 ? prev - 1 : items.length - 1;
          setInputValue(items[nextIndex]);
          e.preventDefault();
          return nextIndex;
        });
      } else if (e.key === 'Escape') {
        setIsInputFocused(false);
        setSelectedIndex(-1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [items, isInputFocused, setInputValue, setIsInputFocused]);

  return (
    <>
      {!isSearching && isInputFocused && recentHistory.length > 0 && (
        <div className={styles.history} onMouseDown={stopEvent}>
          <div className={styles.history_headline}>
            <p className={styles.title}>{t('searchBlock.searched')}</p>
            <span
              tabIndex={0}
              role="button"
              onClick={clearHistory}
              className={styles.clear_history}
              onKeyDown={(e) => { if (e.key === 'Enter') clearHistory(); }}
            >
              {t('clear')}
            </span>
          </div>
          <List
            iconName="clock"
            items={recentHistory}
            onItemClick={handleSuggestionClick}
            selectedIndex={selectedIndex}
          />
        </div>
      )}

      {isSearching && isInputFocused && suggestions && suggestions?.length > 0 && (
        <div className={styles.history} onMouseDown={stopEvent}>
          <List
            iconName="search"
            items={suggestions}
            onItemClick={handleSuggestionClick}
            selectedIndex={selectedIndex}
          />
        </div>
      )}
    </>
  );
};
