import React, { useCallback, useState } from 'react';
import type { IAd } from '@/modules/ads/types';
import { AdImage } from '@/modules/ads/components/ad-image';
import { AdLink } from '@/modules/ads/components/ad-link';
import { ClickAwayListener } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { useTranslateLocation } from '@/modules/locations';
import {
  AppTooltip, Button, Checkbox, SvgIcon,
} from '@/modules/shared/components';
import { useFormatDataPrice, useMatchMedia } from '@/modules/shared/hooks';
import styles from './mobile-my-ad-card.module.scss';
import { ActionButtons } from './action-buttons.component';
import { ModalEdit } from './modal-edit.component';
import { ModalEditPrice, ModalSuccess } from './modal-edit-price';

interface MobileMyAdCardProps {
  ad: IAd;
  adType: string;
  isChoosing: boolean;
  isSelected: number[];
  onSelect: (id: React.SetStateAction<number>) => void;
  onClick: (id: React.SetStateAction<number>) => void;
  isCompany: boolean;
}

export const MobileMyAdCard = ({
  ad, adType, onSelect, isSelected, onClick, isChoosing, isCompany,
}: MobileMyAdCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<'edit' | 'edit_price' | 'success' | null>(null);

  const { t } = useTranslation('userProfile');
  const translateLocations = useTranslateLocation(
    ad?.city.codename,
    ad?.district?.codename,
  );
  const priceDisplayFormat = useFormatDataPrice();
  const { isMobile } = useMatchMedia();

  const handleEditModal = useCallback(
    () => setModalOpen((prevValue) => (prevValue === 'edit' ? null : 'edit')),
    [],
  );

  return (
    <>
      <div className={styles.mobile_card}>
        <div className={styles.image}>
          <AdLink ad={ad}>
            {ad.images[0] ? (
              <AdImage ad={ad} />
            ) : (
              <SvgIcon name={isMobile ? 'mobile-b_no_bg' : 'b_no_bg'} />
            )}
          </AdLink>
        </div>
        <div className={styles.card_content}>
          <div className={styles.ad_info}>
            <div className={styles.title}>
              <p>
                {adType === 'drafts' ? (
                  t('myAds.notSpecifiedPrice')
                ) : (
                  <>
                    {priceDisplayFormat({
                      priceType: ad?.price_type,
                      price: ad?.price,
                    })}
                    {ad?.price_type === 'for_money' && <SvgIcon name="THB" />}
                  </>
                )}
              </p>
              <AdLink ad={ad} className={styles.title}>
                <AppTooltip title={ad.title}>
                  <h4>
                    {adType === 'drafts'
                      ? t('myAds.notSpecifiedTitle')
                      : ad?.title}
                  </h4>
                </AppTooltip>
              </AdLink>
            </div>
            <div className={styles.location}>
              <SvgIcon name="geo-point" />
              <p>{translateLocations}</p>
            </div>
          </div>
          <div className={styles.actions}>
            {isChoosing ? (
              <Checkbox
                checkboxSize="24"
                checked={!!isSelected.includes(ad?.id)}
                onChange={() => onSelect(ad?.id)}
              />
            ) : (
              <>
                <ActionButtons
                  adType={adType}
                  adId={ad?.id}
                  onClick={onClick}
                  onEdit={handleEditModal}
                  isCompany={isCompany}
                />
                {adType === 'drafts' && (
                  <div className={styles.drafts_buttons}>
                    {isMobile && (
                      <SvgIcon
                        name="vertical-menu"
                        className={styles.menu}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    )}

                    {!isMobile && (
                      <>
                        {isCompany && <SvgIcon name="pencil" className={styles.edit} />}
                        <SvgIcon
                          name="trash-bin"
                          className={styles.delete}
                          onClick={() => onClick(ad?.id)}
                        />
                      </>
                    )}
                    {isMobile && isOpen && (
                      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <div className={styles.action_modal}>
                          {isCompany && (
                            <Button
                              appearance="secondary"
                              buttonColor="black"
                              buttonSize="s"
                              className={styles.edit_button}
                              fullWidth
                            >
                              {t('myAds.edit')}
                              <SvgIcon name="pencil" />
                            </Button>
                          )}
                          <Button
                            appearance="secondary"
                            buttonColor="black"
                            buttonSize="s"
                            fullWidth
                            className={styles.delete_button}
                            onClick={() => onClick(ad?.id)}
                          >
                            {t('myAds.delete')}
                            <SvgIcon name="trash-bin" />
                          </Button>
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalEdit
        isEditModalOpen={modalOpen === 'edit'}
        handleEditModal={handleEditModal}
        editPrice={() => setModalOpen('edit_price')}
        codenames={{
          id: ad.id,
          title: ad.title,
          category: ad.category_codename,
          subcategory: ad?.sub_category_codename,
          underSubcategory: ad?.under_sub_category_codename,
        }}
      />
      <ModalEditPrice
        open={modalOpen === 'edit_price'}
        onClose={() => setModalOpen(null)}
        onCancel={() => setModalOpen('edit')}
        id={ad.id}
        price={ad.price}
        price_type={ad.price_type}
        onSuccess={() => setModalOpen('success')}
      />
      <ModalSuccess
        open={modalOpen === 'success'}
        onClose={() => setModalOpen(null)}
        id={ad.id}
      />
    </>
  );
};
