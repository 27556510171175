import React, { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useSwipeable } from 'react-swipeable';
import { Dots } from '@/modules/shared/components';
import { Modal } from '@/modules/shared/components/modal';
import { useMatchMedia } from '@/modules/shared/hooks';
import { OnboardingButtons } from './onboarding-buttons.component';
import { type ModalConfig } from '../type';
import styles from './onboarding-modal.module.scss';


interface Props {
  config: ModalConfig;
  closeOnboarding: (id:string) => void;
}

export const OnboardingModal = ({ config, closeOnboarding }: Props) => {
  const { t } = useTranslation();
  const { isTablet, isDesktop } = useMatchMedia();

  const { completionKey, steps } = config;
  const [currentStep, setCurrentStep] = useState(0);

  const handlePrev = () => {
    if (!steps.length || steps.length === 1) return;
    setCurrentStep((prevIndex) => (prevIndex === 0 ? steps.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    if (!steps.length || steps.length === 1) return;

    setCurrentStep((prevIndex) => (prevIndex === steps.length - 1 ? 0 : prevIndex + 1));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const handleClose = () => {
    closeOnboarding(completionKey);
  };


  const handleOnboardingAction = useCallback(
    (actionType: string) => {
      switch (actionType) {
        case 'NEXT_STEP':
          handleNext();
          break;
        case 'BACK':
          handlePrev();
          break;
        case 'CLOSE':
          handleClose();
          break;
        case 'ROUTER_PUSH':
          handleClose();
          break;
        default:
          console.warn('Unknown actionType:', actionType);
      }
    },
    [],
  );

  const currentStepData = steps[currentStep];


  return (
    <Modal
      fullHeight
      open
      onClose={handleClose}
      hasHeader={false}
      showBackButton={false}
      headerBordered
      showCloseButton={!isTablet}
      isMobile={isTablet}
    >
      <div className={styles.slider} {...swipeHandlers}>
        {currentStepData && (
          <div key={currentStepData.stepIndex} className={styles.slide}>
            <div className={styles.top}>
              <div className={styles.top_image}>
                <img
                  src={isDesktop ? currentStepData.images.desktop : currentStepData.images.mobile}
                  alt={t(currentStepData.title)}
                />
              </div>
              <div className={styles.top_text}>
                <h3>{t(currentStepData.title)}</h3>
                {currentStepData.description.map((paragraph, i) => (
                  <p key={i}>{t(paragraph)}</p>
                ))}
              </div>
            </div>

            <div className={styles.bottom}>
              {steps.length > 1 && (
                <Dots count={steps.length} onClick={setCurrentStep} activeIndex={currentStep} />
              )}

              <div className={styles.bottom_buttons}>
                <OnboardingButtons
                  currentStep={currentStep}
                  totalSteps={steps.length}
                  handleOnboardingAction={handleOnboardingAction}
                  button={currentStepData.button}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
