import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { EventCard } from '../event-card';
import { IEvent } from '../../types';
import styles from './event-card-list.module.scss';

export type EventCardListProps = {
  data: IEvent[];
  title?: string;
};

export const EventCardList = ({ data, title, ...props } : EventCardListProps) => {
  const { t } = useTranslation('');

  return (
    <div className={styles.wrapper}>
      {title && data.length > 0 && <h2 className={styles.wrapper_title}>{t(title)}</h2>}
      <div className={styles.list} {...props}>
        {data?.map((poster: IEvent) => <EventCard key={poster.id} data={poster} />)}
      </div>
    </div>
  );
};
