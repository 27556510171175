import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Slide } from '@mui/material';
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';

import { ModalRoot } from './root/modal-root';
import { SvgIcon } from '../svg-icon';
import { ModalProps } from './types';
import { useSwipeToCloseHook } from '../../hooks';

import { ModalBackDrop } from './back-drop/modal-back-drop';

import styles from './modal-mobile.module.scss';


export const ModalMobile: FC<ModalProps> = ({
  open = false,
  fullHeight,
  title,
  children,
  footer,
  onClose,
  onClickBack,
  showBackButton = true,
  showCloseButton = true,
  headerBordered,
  contentStyles,
  className,
  classNameHeader,
  isDark,
  isClean,
  almostFullHeight,
  closeButtonDelay,
  isLeftSlot = true,
}) => {
  const [isCloseVisible, setIsCloseVisible] = useState(false);

  useEffect(() => {
    if (showCloseButton) {
      if (closeButtonDelay && closeButtonDelay > 0) {
        const timer = setTimeout(() => {
          setIsCloseVisible(true); // показываем кнопку после задержки
        }, closeButtonDelay);

        return () => clearTimeout(timer);
      }
      setIsCloseVisible(true); // показываем кнопку сразу, если таймер не задан
    } else {
      setIsCloseVisible(false); // скрываем кнопку, если showCloseButton === false
    }
  }, [closeButtonDelay, showCloseButton]);

  const handleClose = useCallback(() => {
    // если задан таймер и он еще не прошел, запрещаем закрытие модалки при клике на задний фон
    if (!closeButtonDelay || isCloseVisible) {
      onClose?.(false);
    }
  }, [isCloseVisible, closeButtonDelay, onClose]);

  const swipeRefCallback = useSwipeToCloseHook(handleClose);

  return (
    <BaseModal
      open={open}
      closeAfterTransition
      onClose={handleClose}
      slots={{
        backdrop: ModalBackDrop,
        root: ModalRoot,
      }}
    >
      <Slide direction="up" in={open}>
        <div className={clsx(styles.modal, {
          [styles.modal_dark]: isDark,
          [styles.modal_clean]: isClean,
          [styles.modal_fullHeight]: fullHeight,
          [styles.modal_almostFullHeight]: almostFullHeight,
        }, className)}
        >
          <div
            className={clsx(styles.modal__header, classNameHeader, {
              [styles.modal__header_bordered]: headerBordered,
            })}
            ref={swipeRefCallback}
          >
            {isLeftSlot && (
              <div className={styles.modal__action}>
                {showBackButton && (
                  <button onClick={onClickBack}>
                    <SvgIcon name="arrow-back" />
                  </button>
                )}
              </div>
            )}
            <div className={styles.modal__title}>
              {title}
            </div>
            <div className={styles.modal__action}>
              {showCloseButton && (
                <button
                  onClick={handleClose}
                  className={clsx(styles.close_button, { [styles.close_visible]: isCloseVisible })}
                >
                  <SvgIcon name="close-icon" />
                </button>
              )}
            </div>
          </div>
          <div className={clsx(styles.modal__content, contentStyles)}>
            {children}
          </div>
          {footer && (
            <div className={styles.modal__footer}>
              {footer}
            </div>
          )}
        </div>
      </Slide>
    </BaseModal>
  );
};
