import { useMemo } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { User, UserTypeEnum } from '@/modules/users';
import type { Company } from '@/modules/company/types';
import { useUserProfile } from '@/modules/user-profile/queries';

import { Button } from '../button';
import { SvgIcon } from '../svg-icon';
import { UserLabel } from '../user-label';
import { UserAvatar } from '../user-avatar';
import { UserRating } from '../user-rating';
import { useLastOnline, useMatchMedia } from '../../hooks';
import { UserCardType } from '../../types';
import { FloatNumber } from '../float-number';
import { UserCompanyRedirect } from '../user-company-redirect';
import { formatDateMonthYear, getDeclension, getUserCompanyData } from '../../helpers';
import styles from './user-info.module.scss';

interface UserCardProps {
  card: UserCardType;
  userData: User;
  companyData?: Company;
  isDisabled?: boolean;
  openModal?: () => void;
  showReviews?: () => void;
  scrollToReviews?: () => void;
  goToTelegram?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const UserInfo = ({
  card,
  userData,
  companyData,
  isDisabled,
  openModal,
  showReviews,
  scrollToReviews,
  goToTelegram,
}: UserCardProps) => {
  const { t, lang } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { data: intervalUserData } = useUserProfile(userData?.id);
  const lastOnlineStatus = useLastOnline(intervalUserData?.last_online || '', t, lang, 'lastOnlineUserProfile');

  const { name, avatar } = getUserCompanyData(companyData, userData);
  const isCompany = userData.user_type === UserTypeEnum.Company && userData.company_id;
  const companyLinks = companyData?.social_networks;

  const createdAt = useMemo(() => formatDateMonthYear({
    date: userData.created_at || '',
    t,
    translateMonth: 'common:months',
  }), [t, userData]);

  const averageRating = useMemo(() => getDeclension({
    count: userData?.reviews_received_count,
    t,
    singular: 'common:reviews.one',
    few: 'common:reviews.few',
    many: 'common:reviews.many',
  }), [userData, t]);

  const content = (
    <>
      <div
        className={clsx(
          { [styles.user_ad]: card === 'adUser' },
          { [styles.user_profile]: card === 'userProfile' },
        )}
      >
        <UserAvatar
          srcAvatar={avatar}
          userName={name}
          diameter={isMobile && card === 'adUser' ? '44px' : isTablet ? '44px' : '64px'}
          isOnline={intervalUserData?.is_online}
        />

        <div className={styles.contact}>
          <span className={styles.name}>
            <h2>{name}</h2>
          </span>
          <UserLabel companyId={userData?.company_id} />

          <div className={styles.tech_info}>
            <p className={styles.created}>
              {t('common:onBazaar')}
              {!isDesktop ? <b>{createdAt}</b> : createdAt}
            </p>
            <p className={clsx(
              styles.status,
              { [styles.status__online]: intervalUserData?.is_online },
            )}
            >
              {intervalUserData?.is_online
                ? t('common:online')
                : (intervalUserData?.last_online ? lastOnlineStatus : t('common:lastOnlineUserProfile.longAgo'))}
            </p>
          </div>
        </div>

        <div className={styles.information}>
          <div className={styles.user}>
            <div className={styles.user_block}>
              <span className={styles.user_rating}>
                <FloatNumber
                  value={userData.average_rating}
                  precision={1}
                  showRatingSuffix={false}
                />
              </span>
              <UserRating rating={userData.average_rating} sizeStar="16px" />
            </div>
            <button
              type="button"
              className={styles.user_reviews}
              onClick={card === 'userProfile' ? isDesktop ? scrollToReviews : showReviews : undefined}
            >
              {averageRating}
            </button>
          </div>

          {isMobile && isCompany && card === 'userProfile' && (
            <button
              className={styles.company_info}
              onClick={openModal}
              type="button"
            >
              <SvgIcon name="info" />
              {t('company:info')}
            </button>
          )}
        </div>
      </div>
      {companyLinks?.telegram && isMobile && (
        <Button
          as="link"
          fullWidth
          target="_blank"
          buttonSize="xs"
          buttonColor="grey"
          appearance="primary"
          disabled={isDisabled}
          onClick={goToTelegram}
          href={`https://t.me/${companyLinks?.telegram}`}
          className={clsx(styles.telegram, { [styles.telegram_ad]: card === 'adUser' })}
        >
          {t('adv:contacts.telegram')}
        </Button>
      )}
    </>
  );

  return card === 'adUser' ? (
    <UserCompanyRedirect data={{ user: userData.id, company: userData?.company_id }}>
      {content}
    </UserCompanyRedirect>
  ) : (
    content
  );
};
