import { NextPage, NextPageContext } from 'next';
import { triggerEvent } from '@/modules/rollbar';
import { isServerSide } from '@/modules/shared/helpers';

import { bindContext, createRequestContext } from '@/modules/shared/api';
import { CategoriesApi } from '../api';

export const withPreloadedCategories = (Component: NextPage) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = (async (app: NextPageContext) => {
    const pageProps = await Component.getInitialProps?.(app);

    if (isServerSide()) {
      try {
        const preloadedCategories = (await bindContext(CategoriesApi.list, createRequestContext(app))()).data;

        return {
          ...pageProps,
          preloadedCategories,
        };
      } catch (e) {
        console.log('Не удалось подгрузить категории на стороне SSR', e);
        triggerEvent(new Error('Failed to fetch categories on server side'));
      }
    }
  }) as (app: NextPageContext) => Promise<object>;

  Wrapper.displayName = `withPreloadedCategories(${Component.displayName})`;

  return Wrapper;
};
