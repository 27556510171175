import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { OnboardingButton, OnboardingActionType } from '../type';

interface Props {
  currentStep: number;
  totalSteps: number;
  handleOnboardingAction: (actionType: string) => void;
  button?: OnboardingButton;
}

export const OnboardingButtons: React.FC<Props> = ({
  currentStep, totalSteps, handleOnboardingAction, button,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();


  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;


  const primaryButton = isLastStep
  && button?.done?.actionType === OnboardingActionType.ROUTER_PUSH ? {
      label: t(button.done.label),
      onClick: () => handleOnboardingAction('ROUTER_PUSH'),
      as: 'link' as const,
      href: button.done?.pathRouter?.[isMobile || isTablet ? 'mobile' : 'desktop'],
    } : {
      label: isFirstStep ? t('onboarding:actions.learnMore') : t('onboarding:actions.continue'),
      onClick: () => handleOnboardingAction('NEXT_STEP'),
    };

  const secondaryButton = {
    label: isFirstStep ? t('onboarding:actions.uninteresting') : t('onboarding:actions.back'),
    onClick: () => handleOnboardingAction(isFirstStep ? 'CLOSE' : 'BACK'),
  };

  return (
    <>
      <Button
        buttonSize="s"
        buttonColor={(isDesktop || isTablet) ? 'grey' : 'green'}
        appearance={(isDesktop || isTablet) ? 'primary' : 'secondary'}
        fullWidth
        onClick={secondaryButton.onClick}
      >
        {secondaryButton.label}
      </Button>

      <Button
        buttonSize="s"
        buttonColor="green"
        appearance="primary"
        fullWidth
        {...primaryButton}
      >
        {primaryButton.label}
      </Button>
    </>
  );
};
