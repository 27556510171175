import { useFormContext, useWatch } from 'react-hook-form';
import { useNetworkState } from '@react-hookz/web';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button, Spinner } from '@/modules/shared/components';
import { useEffect, useState } from 'react';
import { IAd } from '../../../types';
import styles from '../edit-ad.module.scss';
import { EditAdTypes } from '../types';

interface EditAdButtonsProps {
  ad: IAd;
}

export const EditAdButtons = ({ ad } :EditAdButtonsProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { online: isOnline } = useNetworkState();
  const [online, setOnline] = useState(true);

  useEffect(() => {
    // чтобы не было ошибок гидрации
    setOnline(Boolean(isOnline));
  }, [isOnline]);

  const {
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useFormContext<EditAdTypes>();

  const [title, description, price, price_type] = useWatch<EditAdTypes>({
    name: ['title', 'description', 'price', 'price_type'],
  });
  const isFormChanged = title !== ad.title
    || description !== ad.description
    || (price !== ad.price.toString() && price_type === 'for_money')
    || price_type !== ad.price_type;

  const disable = !isFormChanged || isSubmitting || isSubmitted || Object.keys(errors).length > 0;

  return (
    <div className={styles.buttons}>
      {!online ? (
        <div className={styles.no_internet}>
          <Spinner size={24} />
          {t('createAd:noInternet')}
        </div>
      ) : (
        <>
          <Button
            as="button"
            type="submit"
            buttonSize={isDesktop ? 'm' : 's'}
            buttonColor="green"
            appearance="primary"
            className={styles.save_button}
            fullWidth={!isDesktop}
            disabled={disable}
          >
            {isSubmitting
              ? t('edit-ad:form.buttons.saving')
              : t('edit-ad:form.buttons.save')}
          </Button>
          <Button
            as="button"
            type="button"
            buttonSize="m"
            buttonColor="green"
            appearance="secondary"
            className={styles.cancel_button}
            fullWidth={!isDesktop}
            onClick={reset}
          >
            {t('edit-ad:form.buttons.cancel')}
          </Button>
        </>
      )}
    </div>
  );
};
