import useTranslation from 'next-translate/useTranslation';
import { Button, Modal } from '@/modules/shared/components';
import { ModalSuccessProps } from './types';
import s from './modal-edit-price.module.scss';

export const ModalSuccess = ({ open, onClose, id }: ModalSuccessProps) => {
  const { t } = useTranslation('userProfile');

  return (
    <Modal
      open={open}
      title={t('editSuccessModal.title')}
      onClose={onClose}
      titleJustifyContent="start"
      showBackButton={false}
      isLeftSlot={false}
      className={s.modal}
    >
      <div className={s.buttons__success}>
        <Button
          as="link"
          buttonSize="s"
          appearance="primary"
          buttonColor="grey"
          fullWidth
          href={`/adv/${id}`}
          className={s.button}
        >
          {t('editSuccessModal.goToAd')}
        </Button>
        <Button
          buttonSize="s"
          appearance="primary"
          buttonColor="grey"
          fullWidth
          onClick={onClose}
          className={s.button}
        >
          {t('editSuccessModal.editAnotherAd')}
        </Button>
      </div>
    </Modal>
  );
};
