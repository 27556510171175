import React, { useCallback, useEffect } from 'react';
import { useSetQueryParams } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import { useHomeCity } from '@/modules/locations/hooks';
import { SeoCatalog } from '@/modules/seo';
import { AdsInfiniteList } from './ads-infinity-list.component';
import { InfinityAds } from '../../types';

type Props = {
  pages: InfinityAds;
  host: string;
};

export const AdsInfiniteListPage = ({ pages, host }: Props) => {
  const setQueryParams = useSetQueryParams();
  const { cityId } = useHomeCity();

  const handleOrderChange = useCallback((value: string) => {
    setQueryParams({ order: value });
  }, [setQueryParams]);
  const { order } = useRouter().query;

  useEffect(() => () => {
    sessionStorage.removeItem('selected_category');
  }, []);

  return (
    <>
      <SeoCatalog ads={pages?.results} baseUrl={host} />
      <AdsInfiniteList
        preloadedPages={pages}
        cityId={cityId}
        order={order as string || '-created_at'}
        onOrderChange={handleOrderChange}
      />
    </>
  );
};
